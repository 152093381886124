import styled, { css } from "styled-components";
import { useState, useEffect, useRef } from "react";
import { FaArrowRight } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { slideFromLeft, slideFromRight } from "../Animations/Animations";
import { FaInstagram, FaWhatsapp, FaTiktok } from "react-icons/fa";
import logo from "../../Assets/logoBranca.png";
import imgFooter from "../../Assets/ImgFooter.jpg";

export default function Footer() {
  const [isVisible, setIsVisible] = useState(false);
  const footerRef = useRef();
  const location = useLocation();

  const isHomePage = location.pathname === "/"; 

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      {
        threshold: 0.1, // Inicia a animação quando 10% do elemento estiver visível
      }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  return (
    <>
      <Container ref={footerRef} isHomePage={isHomePage}>
        <Certificados>
          <Foto isVisible={isVisible}>
            <img src={imgFooter}/>
            <Direitos>
              <div>©2024 VOECHEAP. Todos os direitos reservados.</div>
            </Direitos>
          </Foto>
          <Institucional isVisible={isVisible}>
            <h1>INSTITUCIONAL</h1>
            <div className="columns-container">
              <a href="#" className="link"><h2>Sobre Nós</h2></a>
              <a href="/privacy" className="link"><h2>Políticas de Privacidade</h2></a>
              <a href="/contact" className="link"><h2>Central de Ajuda</h2></a>
            </div>
          </Institucional>
          <Newsletter isVisible={isVisible}>
            <h1>Cadastre-se e receba <span>novidades</span> e <span> promoções</span> exclusivas:</h1>
            <form action="/submit-email" method="post">
              <input type="email" id="email" name="email" placeholder="Digite seu melhor email" required />
              <button type="submit"><FaArrowRight />
              </button>
            </form>
            <h2>Obrigado!</h2>
          </Newsletter>
          <Redes>
            <div>
            <FaInstagram />
            <FaTiktok />
            <FaWhatsapp />
            </div>
            <Logo src={logo} alt="logo"/>
          </Redes>
        </Certificados>
      </Container>
    </>
  );
};


const Container = styled.div`
  position: relative;
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isHomePage ? '#839CC2' : '')};
`;

const Direitos = styled.div`
  background-color: #0c1a3b;
  width: 100%;
  text-align: center;
  display: flex;
  text-align: start;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: -5px;

  div {
    margin-top: 10px;
    height: 15px;
  }
`;

const Certificados = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 200px;
  background-color: #0c1a3b;
  width: 100%;

  @media (min-width: 750px) {
    flex-direction: row;
    margin-top: 150px;
    align-items: center;
    justify-content: space-evenly;
    height: 300px;
  }
  @media (min-width: 950px) {
    margin-top: 350px;
    padding-bottom: 30px;
    padding-top: 30px;
  }
`;

const Institucional = styled.div`
  ${({ isVisible }) => isVisible && css`
    animation: ${slideFromRight} 1.5s ease-out forwards;
  `}
  
  @media(max-width: 750px){
    h1{
      display: none;
    }
  }
  h1 {
    text-align: center; /* Opcional: centraliza o título */
    margin-top: 0px;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: bold;
  }

  .columns-container {
    width: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px; /* Ajuste conforme necessário */
    margin-bottom: 5px;

    @media (min-width: 750px) {
      display: inline;
      justify-content: space-between;
    }
  }

  h2 {
    margin-bottom: -5px;
    font-size: 13.5px;
    color: white;
    font-family: "Montserrat", sans-serif;
  }

  a.link {
    text-decoration-color: white/* sua cor desejada */;
    margin-left: 15px;

    @media (min-width: 750px) {
      margin-top: -10px;
    }
  }

  @media (min-width: 950px) {
    margin-left: -100px;
    margin-top: 15px;
  }
  @media(max-width:600px){
    display:none;
  }
  @media (min-width: 768px) and (min-height: 1024px){
    h2{
      font-size: 10px;
    }
  }
`;

const Newsletter = styled.div`
  ${({ isVisible }) => isVisible && css`
    animation: ${slideFromRight} 1.5s ease-out forwards;
  `}
  
  text-align: center;
  color: white;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;

  @media(max-width:750px){
    h2{
      display: none;
    }
  }
  @media (min-width: 750px){
    width: 210px;
    text-align: left;
  }
  @media (min-width:950px){
    width: 300px;
    h1{
      font-size:18px;
    }
  }
  @media (min-width: 768px) and (min-height: 1024px){
    h1{
      font-size:13px;
      padding-top:5px;
    }
  }
  h2{
    font-size: 14px;
    margin-top: 15px;
  }
span{
  font-weight: bold;
}
  form {
    margin-top: 10px;
    display: inline-block;
    position: relative;
    @media (min-width: 750px){
    width: 100%;
  }
  }
  input {
    border-radius: 10px;
    border: 1px solid #ccc;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 300px;
    display: flex;
    justify-content: end;
    @media (min-width: 750px){
    width: 100%;
  }
  @media(min-width:950px){
    width: 300px;
  }
  }

  button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #007BFF;
    display:none;
  }

  button svg {
    color: #3464AD;
    font-size: 20px;
  }
  @media(max-width:600px){
    height: 100px;
    padding: 15px;
    font-size:17px;
    line-height: 1.5;
    h1{
      width:280px;
    }
    input{
      width:200px;
      border-radius:20px;
    }
  }
`;

const Foto = styled.div`
  ${({ isVisible }) => isVisible && css`
    animation: ${slideFromLeft} 1.5s ease-out forwards;
  `}
  
  margin-top: -180px; /* Ajuste conforme necessário */
  transform: translateX(-50%);
  margin-left: 0px;

  img {
    width: 280px;
    height: 280px;
  }
  @media(max-width:600px){
    display:none;
  }

  @media (min-width: 768px) and (min-height: 1024px){
    margin-top:-120px;
    img{
      width: 180px;
      height: 180px;
  }
  }
`;

const Redes = styled.div`
width: 100%;
height: 100px;
background-color: #748ec5;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 15px;
div{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
}
svg {
    width: 30px; /* Defina o tamanho desejado */
    height: 30px;
    color: white;
  }
@media(min-width: 600px){
  display: none;
}
`;

const Logo = styled.img`
width: 125px;
`;