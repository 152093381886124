import styled from "styled-components";
import Header from "./InitialPage/Header";
import { useState } from "react";
import Footer from "./InitialPage/Footer";
import capaFaq from "../Assets/CapaFaq.jpg";

export default function Faq() {

  const [activeTopicIndex, setActiveTopicIndex] = useState(null);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const faqData = [
    {
      topic: "Passagem Aérea",
      questions: [
        {
          question: "Como é feita a emissão da passagem?",
          answer:
            "Após acordo homologado com um contrato de compra e venda, e assinado pelo aplicativo do governo entre ambas as partes, emitiremos o bilhete direto na cia aérea, enviaremos as passagens via e-mail e WhatsApp , você terá a possibilidade de verificar a passagem no site ou app da cia, o pagamento só será realizado após todo esse processo.",
        },
        {
          question: "Como funciona o prazo para pagamento?",
          answer:
            "Você terá 15 horas para conferir os dados da sua passagem no site da companhia aérea e realizar o pagamento.",
        },
        {
          question: "Preciso ter milhas para comprar?",
          answer:
            "Não, você não precisa ter milhas! Nós utilizamos as nossas para garantir o melhor preço para você.",
        },
        {
          question: "Os valores incluem taxas de embarque?",
          answer:
            "Sim, nossos preços já incluem todas as taxas obrigatórias.",
        },
      ],
    },
    {
      topic: "Alterações e Cancelamentos",
      questions: [
        {
          question: "Posso alterar a data do meu voo?",
          answer:
            "Sim, as alterações estão sujeitas às regras da companhia aérea e a possíveis diferenças tarifárias. Entre em contato com nossa equipe para verificar as opções disponíveis.",
        },
        {
          question: "Como solicito o cancelamento da minha passagem?",
          answer:
            "O cancelamento deve ser solicitado diretamente pelo nosso canal de atendimento e está sujeito às condições da tarifa adquirida.",
        },
      ],
    },
    {
      topic: "Política de Reembolso",
      questions: [
        {
          question: "Quanto tempo demora para receber meu reembolso?",
          answer:
            "O prazo pode variar entre 30 e 90 dias, conforme as regras da companhia aérea.",
        },
        {
          question: "O reembolso inclui as taxas de embarque?",
          answer: "Sim, as taxas de embarque são reembolsáveis.",
        },
      ],
    },
    {
      topic: "Check-in e Embarque",
      questions: [
        {
          question: "Vocês fazem o check-in por mim?",
          answer:
            "Não diretamente, nossa equipe pode auxiliá-lo com o check-in e escolha de assento, mas os termos e a documentação têm que ser enviados pelo viajante.",
        },
        {
          question: "Qual o prazo para realizar o check-in?",
          answer:
            "O check-in pode ser feito até 48 horas antes do voo, dependendo da companhia aérea.",
        },
      ],
    },
    {
      topic: "Segurança",
      questions: [
        {
          question: "As vendas de passagens da Voecheap são seguras?",
          answer:
            "Sim, todas as vendas realizadas pela Voecheap são feitas sob contrato, garantindo transparência e proteção para nossos clientes.",
        },
        {
          question: "Quando devo realizar o pagamento da passagem?",
          answer:
            "O pagamento só é realizado após você conferir e validar os dados da passagem diretamente no site da companhia aérea.",
        },
        {
          question: "Como posso verificar a autenticidade da minha passagem?",
          answer:
            "Após a emissão, enviamos o localizador do voo para que você consulte e valide as informações no site oficial da companhia aérea.",
        },
        {
          question: "Posso confiar na Voecheap?",
          answer:
            "Sim! Somos especializados em emissões e contamos com uma equipe experiente, além de prezarmos por um atendimento personalizado e seguro.",
        },
      ],
    },
    {
      topic: "Formas de Pagamento",
      questions: [
        {
          question: "Quais são as formas de pagamento aceitas pela Voecheap?",
          answer:
            "Trabalhamos com as seguintes opções de pagamento: À vista via PIX na chave CNPJ, transferência bancária para contas em instituições nacionais, boleto bancário (à vista), cartão de crédito parcelado em até 12x com juros e Wise (pagamentos em moeda estrangeira).",
        },
        {
          question: "Posso parcelar minha compra?",
          answer:
            "Sim, você pode parcelar em até 12 vezes no cartão de crédito, com juros aplicados conforme o número de parcelas.",
        },
        {
          question: "O PIX é seguro?",
          answer:
            "Sim! A chave CNPJ garante segurança e transparência na transação.",
        },
      ],
    },
  ];

  const toggleTopic = (index) => {
    setActiveTopicIndex(activeTopicIndex === index ? null : index);
    setActiveQuestionIndex(null); // Fechar perguntas ao abrir/fechar um tópico
  };

  const toggleQuestion = (index) => {
    setActiveQuestionIndex(activeQuestionIndex === index ? null : index);
  };

  // const toggleFAQ = (index) => {
  //   setActiveIndex(activeIndex === index ? null : index);
  // };

  return (
    <Container>
      <Header />
      <Img capaFaq={capaFaq}><span>PERGUNTAS FREQUENTES</span></Img>
      <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
        <Title> Bem-vindo(a) ao FAQ da Voecheap!</Title>
        <SubTitle>Aqui você encontra respostas para as
          dúvidas mais comuns sobre nossos serviços. Caso não encontre o que
          procura, entre em contato conosco pelos canais de atendimento.</SubTitle>
        <div style={{ maxWidth: "800px", margin: "0 auto" }}>
          {faqData.map((topic, topicIndex) => (
            <div key={topicIndex} style={{ marginBottom: "20px" }}>
              <TopicButton onClick={() => toggleTopic(topicIndex)}>
                {topic.topic}
                <span>
                  {activeTopicIndex === topicIndex ? " ▲" : " ▼"}
                </span>
              </TopicButton>
              {activeTopicIndex === topicIndex &&
                topic.questions.map((item, questionIndex) => (
                  <div
                    key={questionIndex}
                    style={{
                      marginLeft: "20px",
                      borderLeft: "2px solid #ccc",
                      paddingLeft: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <QuestionButton
                      onClick={() => toggleQuestion(questionIndex)}
                    >
                      {item.question}
                      <span>
                        {activeQuestionIndex === questionIndex ? " ▲" : " ▼"}
                      </span>
                    </QuestionButton>
                    {activeQuestionIndex === questionIndex && (
                      <Answer>{item.answer}</Answer>
                    )}
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </Container>
  );
}


const Container = styled.div`
  width: 100%;
`;

const Title = styled.div`
margin-bottom: 20px;
  text-align: center;
  font-size: 25px;
  color: black;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const SubTitle = styled.div`
margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  color: black;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Img = styled.div`
  width: 100%;
  height: 200px;
  background-image:url(${props => props.capaFaq});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
span{
  margin-top: 120px;
font-family: "Montserrat", sans-serif;
font-weight: bold;
color:white
}

`;

const TopicButton = styled.div`
  width: 100%;
  padding: 15px;
  font-family: "Montserrat", sans-serif;  
  text-align: left;
  color: white;
  background:#688BC1;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    width:90%;
  }
`;

const QuestionButton = styled.div`
  width: 100%;
  padding: 10px;
  font-weight: bold;
  text-align: left;
  background: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    width:90%;
  }
`;

const Answer = styled.div`
  padding: 10px;
  font-style: italic;
  background: #f9f9f9;
  margin-top: 5px;
  border-radius: 5px;
`;

// const Description = styled.div`
//   padding: 20px;
//   font-size: 18px;
//   line-height: 1.6;
//   color: #333;
//   font-family: "Merriweather", serif;
// `;

// const SectionTitle = styled.h2`
//   margin-top: 20px;
//   font-size: 24px;
//   color: black;
//   font-family: "Montserrat", sans-serif;
//   font-weight: bold;
// `;

// const Conclusion = styled.h3`
//   margin-top: 30px;
//   font-size: 22px;
//   font-family: "Montserrat", sans-serif;
//   color: black;
//   font-weight: bold;
// `;