import { useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import UserContext from "../../contexts/ContextApi";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import { FaCheck } from "react-icons/fa6";

export default function PackageDetails() {
  const { id } = useParams();
  const { packs } = useContext(UserContext); // Obtém a lista de pacotes do contexto

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);

  const location = useLocation();
  const { pack } = location.state || {};
  function reservarPack() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function handleBackgroundClick(e) {
    if (e.target.id === 'modal-background') {
      closeModal();
    }
  }

  function orderPack(e) {
    e.preventDefault();
    let luggageStatus;
    if (luggage === false) {
      luggageStatus = "Não";
    } else {
      luggageStatus = "Sim";
    }

    let message =
      `    Informações de contato:
    NOME: ${name}
    PASSAGEIROS:
    ADULTO: ${adults}
    CRIANÇA: ${children}
    ORIGEM: ${origin}
    DESTINO: ${selectedPack.destiny}
    MÊS: ${selectedMonth}
    DIAS: ${days}
    BAGAGEM DESPACHADA: ${luggageStatus}
    QUANTAS: ${luggageCount}`;
    const encode = encodeURIComponent(message);
    window.open(`https://wa.me/5532984957373?text=${encode}`);
    setIsModalOpen(false); // Fechar o modal de pedido
    setIsThankYouModalOpen(true); // Abrir o modal de agradecimento
    setName("");
    setOrigin("");
    setAdults(0);
    setChildren(0);
    setDays(0);
    setLuggage(false);
    setLuggageCount(0);
    setSelectedMonth('');
    setIsMonthDropdownOpen(false);
  }


  function handleThankYouModalBackgroundClick(e) {
    if (e.target.id === 'modal-background-thank-you') {
      setIsThankYouModalOpen(false);
    }
  }

  const [name, setName] = useState("");
  const [origin, setOrigin] = useState("");
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [days, setDays] = useState(0);
  const [luggage, setLuggage] = useState(false);
  const [luggageCount, setLuggageCount] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);

  const months = [
    'JANEIRO', 'FEVEREIRO', 'MARÇO', 'ABRIL', 'MAIO', 'JUNHO',
    'JULHO', 'AGOSTO', 'SETEMBRO', 'OUTUBRO', 'NOVEMBRO', 'DEZEMBRO'
  ];

  function toggleMonthDropdown() {
    setIsMonthDropdownOpen(!isMonthDropdownOpen);
  }

  function selectMonth(month) {
    setSelectedMonth(month);
    setIsMonthDropdownOpen(true);
  }

  const handleSwapCities = () => {
    setOrigin(selectedPack.destiny);
  };

  // Encontra o pacote correspondente ao ID
  const selectedPack = packs.find(pack => pack.id === parseInt(id));

  // Verifica se o pacote foi encontrado
  if (!selectedPack) {
    return <div>Pacote não encontrado</div>;
  }
  console.log(selectedPack);
  return (
    <>
      <Header />
      <Img bgImage={selectedPack.image}>
      </Img>
      <WebcrumbsWrapper>
        <MainContent>
          <Title>{pack.title}</Title>
          <section>
            <Paragraph>
              {pack.text}
            </Paragraph>
          </section>
        </MainContent>
        <Aside>
          <PriceCard>
            <div>
              <p className="text-lg text-neutral-500 font-semibold"></p>
              <Price>{selectedPack.price}</Price>
            </div>
            <ul>
              <ListItem>
                <MaterialIcon><FaCheck /></MaterialIcon>
                {selectedPack.ticket}
              </ListItem>
              <ListItem>
                <MaterialIcon><FaCheck /></MaterialIcon>
                {selectedPack.baggage}
              </ListItem>
              <ListItem>
                <MaterialIcon><FaCheck /></MaterialIcon>
                {selectedPack.departureTax}
              </ListItem>
              <ListItem>
                <MaterialIcon><FaCheck /></MaterialIcon>
                {selectedPack.support}
              </ListItem>
            </ul>
            <Button onClick={reservarPack}>Verificar Disponibilidade</Button>
            <HelpText>
              <h5>Dúvidas?{" "}</h5>
              <a href="https://linktr.ee/voecheap" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                Fale com um de nossos agentes
              </a>
            </HelpText>
          </PriceCard>
        </Aside>
        {isModalOpen && (
          <ModalBackground id="modal-background" onClick={handleBackgroundClick}>
            <Modal>
              <Form onSubmit={orderPack}>
                <h1>Olá, viajante!</h1>
                <h2>Preencha os campos abaixo de acordo com suas preferêcias:</h2>
                <SubTitles>Seu nome:</SubTitles>
                <label style={{ display: 'flex' }}>
                  <input style={{ borderRadius: '30px', border: 'none', width: '100%' }} type="text" name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)} required />
                </label>
                <div>
                  <label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <SubTitles>
                        <span>Passageiros: </span>
                      </SubTitles>
                      <SubTitles style={{ marginLeft: '10px' }}>
                        <span >Adultos:</span>
                      </SubTitles>
                      <Counter>
                        <ButtonLeft style={{ textDecoration: 'none' }} type="button" onClick={() => setAdults(Math.max(0, adults - 1))}>-</ButtonLeft>
                        <span>{adults}</span>
                        <ButtonRight style={{ marginRight: '5px' }} type="button" onClick={() => setAdults(adults + 1)}>+</ButtonRight>
                      </Counter>
                      <SubTitles>Crianças:</SubTitles>
                      <Counter>
                        <ButtonLeft style={{ marginLeft: '5px' }} type="button" onClick={() => setChildren(Math.max(0, children - 1))}>-</ButtonLeft>
                        <span>{children}</span>
                        <ButtonRight style={{ marginRight: '5px' }} type="button" onClick={() => setChildren(children + 1)}>+</ButtonRight>
                      </Counter>
                    </div>
                  </label>
                </div>
                <Cities>
                  <label style={{ display: 'flex', flexDirection: 'column' }}>
                    <SubTitlesCity>Cidade de origem:</SubTitlesCity>
                    <City type="text" name="origin" value={origin} onChange={(e) => setOrigin(e.target.value)} required />
                  </label>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ion-icon name="swap-horizontal-outline" onClick={handleSwapCities}></ion-icon>
                  </div>
                  <label style={{ display: 'flex', flexDirection: 'column' }}>
                    <SubTitlesCity>Cidade de destino:</SubTitlesCity>
                    <City type="text" name="destination" value={selectedPack.destiny} readOnly />
                  </label>
                </Cities>
                <div>
                  <SubTitles> Mês da viagem: </SubTitles>
                  <label style={{ display: 'flex', position: 'relative', width: '85px' }}>
                    <input
                      type="text"
                      name="travelMonth"
                      value={selectedMonth}
                      onClick={toggleMonthDropdown}
                      readOnly
                      required
                      placeholder=""
                      style={{
                        cursor: 'pointer', width: '85px', border: 'none',
                        borderRadius: '30px', display: 'flex', paddingLeft: '5px', height: '18px',
                        fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '10px',
                        backgroundImage: 'url(https://cdn-icons-png.flaticon.com/512/271/271210.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '70px center',
                        backgroundSize: '15px'
                      }}

                    />
                    {isMonthDropdownOpen && (
                      <MonthDropdown>
                        {months.map((month, index) => (
                          <MonthItem key={index} onClick={() => selectMonth(month)}>
                            {month}
                          </MonthItem>
                        ))}
                      </MonthDropdown>
                    )}
                  </label>
                </div>
                <div>
                  <label>
                    <SubTitles>Quantos dias pretende ficar?</SubTitles>
                    <Counter>
                      <ButtonLeft type="button" onClick={() => setDays(Math.max(0, days - 1))}>-</ButtonLeft>
                      <span>{days}</span>
                      <ButtonRight type="button" onClick={() => setDays(days + 1)}>+</ButtonRight>
                    </Counter>
                  </label>
                </div>
                <div>
                  <label>
                    <SubTitles>Bagagem despachada (23kg)?</SubTitles>
                    <div>
                      <input
                        type="radio"
                        name="luggage"
                        value="yes"
                        checked={luggage}
                        onChange={() => setLuggage(true)}
                      /> Sim
                      <input
                        type="radio"
                        name="luggage"
                        value="no"
                        checked={!luggage}
                        onChange={() => setLuggage(false)}
                      /> Não
                    </div>
                  </label>
                  {luggage && (
                    <label style={{ marginTop: '5px' }}>
                      <SubTitles>Quantas:</SubTitles>
                      <Counter>
                        <ButtonLeft type="button" onClick={() => setLuggageCount(Math.max(0, luggageCount - 1))}>-</ButtonLeft>
                        <span>{luggageCount}</span>
                        <ButtonRight type="button" onClick={() => setLuggageCount(luggageCount + 1)}>+</ButtonRight>
                      </Counter>
                    </label>
                  )}
                </div>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <button style={{
                    border: 'none', fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '13px',
                    width: '61px', height: '30px', borderRadius: '30px', textAlign: 'center', padding: '5px'
                  }}
                    type="submit">ENVIAR</button>
                </div>
              </Form>
            </Modal>
          </ModalBackground>
        )}
        {isThankYouModalOpen && (
          <ModalThank id="modal-background-thank-you" onClick={handleThankYouModalBackgroundClick}>
            <MiniModal>
              <h1>OBRIGADO!</h1>
              <h2>Nossa equipe já está buscando as <span>melhores opções</span> para você! :)</h2>
              <h3>Aguarde que logo menos um de nossos agentes entrará em contato atráves do número fornecido.</h3>
              <h4>Enquanto isso, vem acompanhar nossas redes: </h4>
              <div>
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Instagram_logo.png/768px-Instagram_logo.png" alt="insta" />
                <img src="https://freepngtransparent.com/wp-content/uploads/2023/03/tiktok-png-158.png" alt="tiktok" />
              </div>
            </MiniModal>
          </ModalThank>
        )}
      </WebcrumbsWrapper>
      <Footer />
    </>
  );
}


const WebcrumbsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 48px;
  width: 90%;
  padding: 32px;
  @media(max-width: 780px){
    flex-direction: column;
    width: 80%;
  }
`;

const MainContent = styled.div`
  flex: 2;
`;

const Title = styled.h1`
 font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: #262626; // text-neutral-950
  margin-bottom: 16px;
  @media(max-width: 780px){
    font-size: 25px;
  }
`;

const Paragraph = styled.p`
 font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 1.625;
  color: #666; // text-neutral-700
  margin-bottom: 24px;
  @media(max-width: 780px){
    font-size: 15px;
  }
`;

const Aside = styled.aside`
  flex: 1;
`;

const PriceCard = styled.div`
  background-color: #f9fafb; // bg-gray-50
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  padding: 24px;
  margin-bottom: 24px;
`;

const Price = styled.p`
  font-size: 32px;
  font-weight: 700;
  color: #262626; // text-neutral-950
  margin-bottom: 8px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #666; // text-neutral-700
  margin-bottom: 12px;
  font-family: "Montserrat", sans-serif;
`;

const Button = styled.button`
  background-color:#0C1A3B; // bg-primary-500
  color: white;
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  text-align: center;
  margin-top: 24px;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
`;

const HelpText = styled.p`
text-align: center;
  font-size: 14px;
  color: #999; // text-neutral-500
  margin-top: 16px;
  font-family: "Montserrat", sans-serif;
`;

const MaterialIcon = styled.span`
 font-family: "Montserrat", sans-serif;
  font-weight: 100;
  font-size: 18px;
  color: #0C1A3B; // text-primary-500
`;

const Img = styled.div`
  width: 100%;
  height: 200px;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
`;

const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

const ModalThank = styled.div`
 position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

const MiniModal = styled.div`
        background: #214488;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    color: white;
    font-family: "Montserrat", sans-serif;
    div{
width: 100px;
height: 30px;
display: flex;
align-items: center;
justify-content: space-around;
img{
    width: 30px;
}
    }
    @media (min-width: 760px) {
    margin-top:-200px;
}
@media (min-width: 850px) and (min-height: 1200px) {
    top: -280px;
}
@media (min-width:900px) {
    width: 500px;
    height: 250px;
    top: 100px;
    text-align: center;
    h1{
        font-size: 50px;
        font-weight: bold;
    }
    h2{
        font-size: 25px;
        span{
            font-weight: bold;
        }
    }
    h4{
        font-size: 25px;
    }
}
`;

const Modal = styled.div`
    background: #214488;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 435px;
    gap: 10px;
    border-radius: 50px;
    @media (min-width: 760px) {
    margin-top:-200px;
}
@media (min-width: 850px) and (min-height: 1200px) {
    top: -280px;
}
@media (min-width:900px) {
    width: 500px;
    height: 435px;
    top: 100px;
}
`;

// const CloseButton = styled.button`
//     position: absolute;
//     top: 10px;
//     right: 10px;
//     background: none;
//     border: none;
//     font-size: 1.5rem;
//     cursor: pointer;
// `;

const Counter = styled.div`
    display: flex;
    align-items: center;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10px;
        height: 20px;
    }
    span {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: black;
    }
`;

const Form = styled.form`
color:white;
font-family: "Montserrat", sans-serif;
font-size: 10px;
h1{
    font-size:40px;
    font-weight: bold;
    margin-bottom:5px;
    @media(min-width:900px){
        font-size: 50px;
    }
}
h2{
    font-size: 15px;
    margin-bottom:10px;
    @media(min-width:900px){
     font-size:15px ;
    }

}
div{
    margin-top: 5px;
}
label{
    margin-top: 5px;
}
input{
    @media(min-width:900px){
        height: 25px;
    }
}
`;

const ButtonLeft = styled.button`
margin-left: 5px;
border: none;
border-right: 1px solid black;
width: 10px;
background-color: white;
`;

const ButtonRight = styled.button`
border: none;
border-left: 1px solid black;
width: 10px;
background-color: white;
`;

const MonthDropdown = styled.div`
    position: absolute;
    top: 15px;
    left: 0;
    background: white;
    border: 1px solid #ccc;
    width: 80px;
    color:black;
    z-index: 1000;
`;

const MonthItem = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
    cursor: pointer;
    &:hover {
        background-color: #f1f1f1;
    }
`;

const SubTitles = styled.h3`
 border-bottom: 2px solid white; 
 display: inline-block; 
 padding-bottom: 0px;
 font-size: 13px;
 @media(min-width:900px){
     font-size:15px;
     white-space: nowrap;
    }
`;

const City = styled.input`                                   
width: 200px;
border: none;
border-radius: 30px; 
margin-top: 5px;                                      
font-family: "Montserrat", sans-serif; 
font-weight: bold;
font-size: 13px;
@media(min-width:900px){
    width: 200px;
}
`;

const SubTitlesCity = styled.h3`
 border-bottom: 2px solid white; 
 display: inline-block; 
 padding-bottom: 0px; 
 width:125px;
 font-size: 13px;
 @media(min-width:900px){
     font-size:15px;
     white-space: nowrap;
     width:140px;
    }
`;

const Cities = styled.div`
display: flex;
justify-content: space-between;
flex-direction:column;
@media(min-width:900px){
    flex-direction: row;
}
ion-icon[name='swap-horizontal-outline'] {
  width: 30px;
  height:30px;
  margin-top: 15px;
}
`;