import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Mia from "../../Assets/MiniaturaMiami.jpg";

export default function NewPacks() {

    const navigate = useNavigate();

    const handleClick = (pack) => {
        navigate(`/package/${pack.id}`, { state: { pack } });
    };

    const packs = [
        {
            id: 1, destiny: "PORTUGAL", image: "https://encurtador.com.br/mAGS5", title:"Descubra Portugal: história, encantos e beleza natural",
            price: "R$2.650/pessoa *Apenas ida", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp",
            departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida",
            text: "Portugal é um dos destinos mais encantadores da Europa, conhecido por sua rica história, paisagens deslumbrantes e cultura vibrante. De norte a sul, o país oferece experiências inesquecíveis: desde as vinícolas do Douro até as praias douradas do Algarve. Lisboa, a charmosa capital, encanta com seus miradouros e bairros históricos, enquanto o Porto surpreende com a combinação de arquitetura e gastronomia. Para quem busca algo além, as ilhas dos Açores e Madeira são refúgios paradisíacos no Atlântico. Viajar para Portugal é explorar castelos medievais, provar vinhos premiados e vivenciar uma hospitalidade única."
        },
        {
            id: 2, destiny: "ORLANDO", image: "https://www.visiteosusa.com.br/sites/default/files/styles/hero_l/public/images/hero_media_image/2021-09/95e7d963-79dc-46f5-9388-4a128a086b2a.jpeg?h=58c8a5e7&itok=c4aYEBPF", title:"Explore Orlando: a capital da diversão",
            price: "R$3.050/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp",
            departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagens de ida e volta",
            text: "Orlando, na Flórida, é um destino mágico para todas as idades. Reconhecida como a capital mundial dos parques temáticos, é o lar de atrações icônicas como Walt Disney World, Universal Studios e SeaWorld. Além dos parques, Orlando oferece uma rica cena gastronômica, opções de compras incríveis e uma vida noturna animada. A cidade também é cercada por belos lagos e reservas naturais, proporcionando momentos de relaxamento em meio à natureza. Viajar para Orlando é mergulhar em um mundo de aventuras, entretenimento e diversão inesquecível."
        },
        {
            id: 4, destiny: "Madrid", image: "https://www.daninoce.com.br/wp-content/uploads/2017/06/o-que-fazer-e-onde-ir-em-um-dia-em-madrid-espanha-dica-de-viagem-danielle-noce-imagem-destaque.jpg", title:"Descubra Madrid: cultura, história e vida vibrante",
            price: "R$4.500/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp",
            departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagens de ida e volta",
            text:"Madrid, a capital da Espanha, é uma cidade que combina tradição e modernidade de forma única. Com uma rica herança histórica, abriga marcos emblemáticos como o Palácio Real e a Plaza Mayor. A cidade é também um centro cultural de destaque, com museus renomados como o Museu do Prado e o Museu Reina Sofía, que exibem obras-primas da arte mundial.A vida noturna madrilenha é famosa por sua energia contagiante, com bares, restaurantes e casas de flamenco que animam as ruas até altas horas. Além disso, Madrid é conhecida por sua hospitalidade calorosa, tornando-se um destino acolhedor para visitantes de todo o mundo. Explorar Madrid é mergulhar em uma metrópole vibrante que preserva seu charme histórico enquanto abraça a inovação e a diversidade cultural."
        },
        {
            id: 19, destiny: "MIAMI", image: Mia, price: "R$2.890/pessoa", title:"Descubra Miami: sol, cultura e estilo",
            support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp",
            departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida",
            text:"Madrid, a capital da Espanha, é uma cidade que combina tradição e modernidade de forma única. Com uma rica herança histórica, abriga marcos emblemáticos como o Palácio Real e a Plaza Mayor. A cidade é também um centro cultural de destaque, com museus renomados como o Museu do Prado e o Museu Reina Sofía, que exibem obras-primas da arte mundial.A vida noturna madrilenha é famosa por sua energia contagiante, com bares, restaurantes e casas de flamenco que animam as ruas até altas horas. Além disso, Madrid é conhecida por sua hospitalidade calorosa, tornando-se um destino acolhedor para visitantes de todo o mundo. Explorar Madrid é mergulhar em uma metrópole vibrante que preserva seu charme histórico enquanto abraça a inovação e a diversidade cultural."
        },
    ];

    return (
        <>
            <Container>
                <Passagens>
                    <First bgImage={packs[0].image} onClick={() => handleClick(packs[0])}>
                        {packs.slice(0, 1).map(pack => (
                            <Text key={pack.id}>
                                <span>{pack.destiny}</span>
                                <p>a partir de {pack.price}</p>
                            </Text>
                        ))}
                    </First>
                    <Subs bgImage={packs[1].image} bgImage2={packs[2].image}>
                        {packs.slice(1, 3).map(pack => (
                            <Text key={pack.id} onClick={() => handleClick(pack)}>
                                <span>{pack.destiny}</span>
                                <p>a partir de {pack.price}</p>
                            </Text>
                        ))}
                    </Subs>
                    <Second bgImage={packs[3].image} onClick={() => handleClick(packs[3])}>
                        {packs.slice(3, 4).map(pack => (
                            <Text key={pack.id}>
                                <span>{pack.destiny}</span>
                                <p>a partir de {pack.price}</p>
                            </Text>
                        ))}
                    </Second>
                </Passagens>
                <MobilePassagens>
                    {packs.map(pack => (
                        <TextMobile key={pack.id} bgImage={pack.image} onClick={() => handleClick(pack)}>
                            <span>{pack.destiny}</span>
                            <p>a partir de {pack.price}</p>
                        </TextMobile>
                    ))}
                </MobilePassagens>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    margin-top: 150px;
    margin-bottom: 150px;
    z-index: 20;
    @media(max-width:600px){
        margin-top: 50px;
        margin-bottom: 50px;
    }
`;

const Passagens = styled.div`
    background-color: #9197a3;
    display: flex;
    width: 1200px;
    height: 180px;
    position: relative;
    overflow: visible;
    justify-content: space-around;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    @media(max-width:600px){
        display: none;
    }
    @media (min-width: 768px) and (min-height: 1024px) {
  width: 100%;
}
`;

const First = styled.div`
    width: 280px;
    height: 350px;
    background-image: url(${props => props.bgImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 50%;
    position: relative;
    top: -100px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 10px;
    transition: transform 0.3s ease;
    overflow: hidden; /* Para garantir que o pseudo-elemento não saia da área do componente */
    border-radius: 20px;

    &:hover {
        transform: scale(1.1); /* Aplica o efeito de zoom */
        p{
                opacity: 1;
                transform: translateY(0);
            }
            span{
            opacity: 1;
            transform: translateY(0);
        }
            &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); /* Escurece a imagem */
        z-index: 1;
        transition: inherit;
    }

    > * {
        position: relative;
        z-index: 2;
    }
 }
 @media (max-width: 767px) {
       &::before {
           content: '';
           position: absolute;
           top: 0;
           left: 0;
           width: 100%;
           height: 100%;
           background: rgba(0, 0, 0, 0.5); /* Escurece a imagem */
           z-index: 1;
       }

       p, span {
           opacity: 1; /* Garante que o texto esteja visível */
           transform: translateY(0); /* Garante que o texto não esteja movendo */
       }
   }
   
    @media (min-width: 768px) and (min-height: 1024px) {
        width: 180px;
        height: 250px;
        top:-50px;
}
`;

const Second = styled.div`
   width: 280px;
   height: 350px;
   background-image: url(${props => props.bgImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 50%;
    position: relative;
    top: -100px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 10px;
    transition: transform 0.3s ease;
    overflow: hidden; /* Para garantir que o pseudo-elemento não saia da área do componente */
    border-radius: 20px;

    &:hover {
        transform: scale(1.1); /* Aplica o efeito de zoom */
        p{
                opacity: 1;
                transform: translateY(0);
            }
            span{
            opacity: 1;
            transform: translateY(0);
        }
            &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); /* Escurece a imagem */
        z-index: 1;
        transition: inherit;
    }

    > * {
        position: relative;
        z-index: 2;
    }
 }


    @media (min-width: 768px) and (min-height: 1024px) {
        width: 180px;
        height: 250px;
        top: -50px;
}
`;

const Subs = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    div:nth-child(1),
    div:nth-child(2) {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 50%;
        width: 250px;
        height: 160px;
        left: -135px;
        padding: 10px;
        transition: transform 0.3s ease;
        overflow: hidden; /* Para garantir que o pseudo-elemento não saia da área do componente */
        border-radius: 20px;
        @media (min-width: 768px) and (min-height: 1024px) {
        width: 180px;
        height: 110px;
        left: -99px;
        }
        &:hover {
        transform: scale(1.1); /* Aplica o efeito de zoom */
        p{
            opacity: 1;
            transform: translateY(0);
        }
        span{
            opacity: 1;
            transform: translateY(0);
        }

        &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); /* Escurece a imagem */
        z-index: 1;
        transition: inherit;
    }

    > * {
        position: relative;
        z-index: 2;
    }
 }
    }

    div:nth-child(1) {
        background-image: url(${props => props.bgImage});
        top: -100px;
        @media (min-width: 768px) and (min-height: 1024px) {
       top:-50px;
}
    }

    div:nth-child(2) {
        background-image: url(${props => props.bgImage2});
        top: 90px;
        @media (min-width: 768px) and (min-height: 1024px) {
        top: 90px;
        }
    }
`;

const Text = styled.div`
    color: white; /* Adjust text color if necessary */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    
    p {
        margin: 0;
        font-size: 15px;
        opacity: 0; /* Inicialmente oculta o texto */
    transform: translateY(20px); /* Inicialmente move o texto para fora da visualização */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Transições suaves */
    }

    span {
        margin-bottom: 5px; /* Add some space between the destination and price */
        font-weight: bold;
        font-size: 20px;
        opacity: 0; /* Inicialmente oculta o texto */
    transform: translateY(20px); /* Inicialmente move o texto para fora da visualização */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Transições suaves */
    }
`;

const MobilePassagens = styled.div`
display: flex;
height: 140vh;
align-items: center;
justify-content: space-evenly;
flex-direction: column;
div{
    width: 80%;
    height: 200px;
    padding: 10px;
    &:hover {
        transform: scale(1); /* Aplica o efeito de zoom */
        p{
            opacity: 1;
            transform: translateY(0);
        }
        span{
            opacity: 1;
            transform: translateY(0);
        }

        &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); /* Escurece a imagem */
        z-index: 1;
        transition: inherit;
    }

    > * {
        position: relative;
        z-index: 2;
    }
 }
}
@media(min-width:600px){
    display: none;
}
`;

const TextMobile = styled.div`
 background-image: url(${props => props.bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
    color: white; /* Adjust text color if necessary */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    font-family: "Montserrat", sans-serif;
    p {
        margin: 0;
        font-size: 15px;
        opacity: 0; /* Inicialmente oculta o texto */
    transform: translateY(20px); /* Inicialmente move o texto para fora da visualização */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Transições suaves */
    }

    span {
        margin-bottom: 5px; /* Add some space between the destination and price */
        font-weight: bold;
        font-size: 20px;
        opacity: 0; /* Inicialmente oculta o texto */
    transform: translateY(20px); /* Inicialmente move o texto para fora da visualização */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Transições suaves */
    }
`;

