import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from 'react';
import Banner01 from "../../Assets/Desbrave.png";
import Banner02 from "../../Assets/Desbrave1.png";
import { useNavigate } from "react-router-dom";
import BannerMobile from "../../Assets/375x450.jpg";
import BannerMobile2 from "../../Assets/375x450-1.jpg";

export default function Slogan() {

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [direction, setDirection] = useState("in"); // Estado para controlar a direção da animação
  const [images, setImages] = useState([Banner01, Banner02]);

  const navigate = useNavigate();

  useEffect(() => {
    const updateImages = () => {
      if (window.innerWidth >= 460) {
        setImages([Banner01, Banner02]);
      } else {
        setImages([BannerMobile, BannerMobile2]);
      }
    };

    updateImages();
    window.addEventListener("resize", updateImages);

    return () => window.removeEventListener("resize", updateImages);
  }, []);

  const handleClick = (pack) => {
    navigate(`/package/${pack.id}`, { state: { pack } });
  };

  const packs = [
    {
      id: 1, destiny: "PORTUGAL", image: "https://encurtador.com.br/mAGS5", title: "Descubra Portugal: história, encantos e beleza natural",
      price: "R$2.650/pessoa *Apenas ida", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp",
      departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida",
      text: "Portugal é um dos destinos mais encantadores da Europa, conhecido por sua rica história, paisagens deslumbrantes e cultura vibrante. De norte a sul, o país oferece experiências inesquecíveis: desde as vinícolas do Douro até as praias douradas do Algarve. Lisboa, a charmosa capital, encanta com seus miradouros e bairros históricos, enquanto o Porto surpreende com a combinação de arquitetura e gastronomia. Para quem busca algo além, as ilhas dos Açores e Madeira são refúgios paradisíacos no Atlântico. Viajar para Portugal é explorar castelos medievais, provar vinhos premiados e vivenciar uma hospitalidade única."
    },
    {
      id: 19, destiny: "MIAMI", image: "https://trendlink.com.br/TxdQw", price: "R$2.890/pessoa", title: "Descubra Miami: sol, cultura e estilo",
      support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp",
      departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida",
      text: "Madrid, a capital da Espanha, é uma cidade que combina tradição e modernidade de forma única. Com uma rica herança histórica, abriga marcos emblemáticos como o Palácio Real e a Plaza Mayor. A cidade é também um centro cultural de destaque, com museus renomados como o Museu do Prado e o Museu Reina Sofía, que exibem obras-primas da arte mundial.A vida noturna madrilenha é famosa por sua energia contagiante, com bares, restaurantes e casas de flamenco que animam as ruas até altas horas. Além disso, Madrid é conhecida por sua hospitalidade calorosa, tornando-se um destino acolhedor para visitantes de todo o mundo. Explorar Madrid é mergulhar em uma metrópole vibrante que preserva seu charme histórico enquanto abraça a inovação e a diversidade cultural."
    },
  ];


  useEffect(() => {
    const interval = setInterval(() => {
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 5000);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handleCircleClick = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <Container>
      {images.length > 0 && (
        <Image capa={images[currentImageIndex]} direction={direction} key={currentImageIndex}>
          <TextArea>
          </TextArea>
          <SaibaMais onClick={() => handleClick(packs[currentImageIndex])} >SAIBA MAIS</SaibaMais>
        </Image>
      )}
      {<Circles>
        {images.map((_, index) => (
          <Circle
            key={index}
            active={index === currentImageIndex}
            onClick={() => handleCircleClick(index)}
          />
        ))}
      </Circles>}
    </Container>
  );
}

const slideInLeft = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOutLeft = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  z-index: 20;
  @media(min-width:750px){
    height: 420px;
  }
  @media(max-width:600px){
    height: 450px;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.capa});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* animation: ${props => props.direction === "in" ? slideInLeft : slideOutLeft} 0.5s ease-in-out; */

  /* ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
   */
  & > * {
    position: relative;
    z-index: 2;
  }
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-family: "Anton", sans-serif;
    font-size: 50px;
    color: white;
  }
  h2 {
    margin-top: -30px;
    font-size: 100px;
    color: rgba(255, 255, 255, 0.5);
    font-family: "Merriweather", serif;
  }
  div {
    width: 100%;
    text-align: end;
    font-family: "Anton", sans-serif;
    color: white;
  }
  @media(max-width:600px){
    h1{
      font-size: 30px;
    }
    h2{
      margin-top: -20px;
      font-size: 60px;
    }
  }
`;

const SaibaMais = styled.div`
  position: absolute;
  background-color:#004AAD;
  top: 350px;
  font-family: "Montserrat", sans-serif;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 20px;
  color: white;
  cursor: pointer;
`;

const Circles = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 10;
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => (props.active ? 'white' : 'transparent')};
  border: 1px solid white;
  transition: background-color 0.3s;
`;
