import { keyframes } from "styled-components";

export const growFromLeft = keyframes`
  0% {
    transform: scaleX(0); 
    transform-origin: left; 
  }
  100% {
    transform: scaleX(1); 
    transform-origin: left; 
  }
`;

export const growFromTop = keyframes`
  0% {
    height: 0;
    opacity: 0; /* Opcional: adiciona um efeito de desvanecimento */
  }
  100% {
    height: 300px; /* Defina a altura final desejada */
    opacity: 1; /* Opcional: adiciona um efeito de desvanecimento */
  }
`;

export const slideFromLeft = keyframes`
  0% {
    transform: translateX(-100px); /* Começa fora da tela à esquerda */
    opacity: 0;
  }
  100% {
    transform: translateX(0); /* Volta à posição original */
    opacity: 1;
  }
`;

export const slideFromRight = keyframes`
  0% {
    transform: translateX(100px); /* Começa fora da tela à direita */
    opacity: 0;
  }
  100% {
    transform: translateX(0); /* Volta à posição original */
    opacity: 1;
  }
`;

export const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;
