import Header from "../Components/InitialPage/Header";
import Slogan from "../Components/InitialPage/Slogan";
import Packages from "../Components/InitialPage/Packages";
import Clients from "../Components/InitialPage/Clients";
import styled from "styled-components";
import Footer from "../Components/InitialPage/Footer";
import Info from "../Components/InitialPage/Info";
import NewPacks from "../Components/InitialPage/NewPacks";
import Notices from "../Components/InitialPage/Notices";
import OpenForm from "../Components/InitialPage/OpenForm";
// import Notices from "../Components/InitialPage/Notices";

export default function InitialPage() {
    
    return (<>
        <Container>
            <Header/>
            <Slogan/>
            <Info/>
            <NewPacks/>
            {/* <Packages /> */}
            {/* <Notices/> */}
            <Clients/>
            <OpenForm/>
            <Footer/>
        </Container>
    </>)
}

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`