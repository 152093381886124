import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../contexts/ContextApi';
import styled from "styled-components";
import axios from 'axios';

export default function EditaPages() {
    const [newImageFile, setNewImageFile] = useState(null);
    const [newImageText, setNewImageText] = useState('');
    const [newImagePrice, setNewImagePrice] = useState('');
    const [images, setImages] = useState([]);
    const [urlPrincipal, setUrlPrincipal] = useState('');
    const [urlSec, setUrlSec] = useState('');
    const [urlTerc, setUrlTerc] = useState('');

    useEffect(() => {
        // Função para buscar imagens da API
        const fetchImages = async () => {
            try {
                const response = await axios.get('http://localhost:5000/slogans');
                setImages(response.data); // Atualiza o contexto com as imagens
            } catch (error) {
                console.error('Erro ao buscar imagens:', error);
            }
        };

        fetchImages(); // Chama a função para buscar as imagens
    }, []);

    const handleAddImage = async () => {
        if (newImageFile && newImageText && newImagePrice) {
            const formData = new FormData();
            formData.append('image', newImageFile); // Arquivo da imagem
            formData.append('texts', newImageText);
            formData.append('price', newImagePrice);
    
            try {
                const response = await axios.post('http://localhost:5000/createslogan', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setImages((prevImages) => [...prevImages, response.data]);
                setNewImageFile(null);
                setNewImageText('');
                setNewImagePrice('');
            } catch (error) {
                console.error('Erro ao adicionar banner:', error);
            }
        }
    };

    const handleImageChange = async (id, e) => {
        const updatedFile = e.target.files[0];
        const formData = new FormData();
        formData.append('image', updatedFile);

        try {
            const response = await axios.patch(`http://localhost:5000/putslogan/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setImages((prevImages) =>
                prevImages.map((img) => (img.id === id ? { ...img, url: response.data.url } : img))
            );
        } catch (error) {
            console.error('Erro ao atualizar a imagem:', error);
        }
    };

    const removeImage = async (id) => {
        try {
            await axios.delete(`http://localhost:5000/deleteslogan/${id}`);
            setImages((prevImages) => prevImages.filter((image) => image.id !== id));
        } catch (error) {
            console.error('Erro ao excluir banner:', error);
        }
    };

    return (
        <Container>
            <h1>Editar Banners</h1>
            {images.map((image) => (
                <ImageItem key={image.id}>
                    <h2>{image.texts}</h2>
                    <label>
                        Alterar imagem:
                        <input type="file" onChange={(e) => handleImageChange(image.id, e)} />
                    </label>
                    <button onClick={() => removeImage(image.id)}>Excluir</button>
                </ImageItem>
            ))}
            <h2>Adicionar Novo Banner</h2>
            <Add>
                <label>
                    Upload da imagem:
                    <input type="file" onChange={(e) => setNewImageFile(e.target.files[0])} />
                </label>
                <label>
                    Nome do país:
                    <input
                        type="text"
                        value={newImageText}
                        onChange={(e) => setNewImageText(e.target.value)}
                    />
                </label>
                <label>
                    Preço:
                    <input
                        type="text"
                        value={newImagePrice}
                        onChange={(e) => setNewImagePrice(e.target.value)}
                    />
                </label>
                <button onClick={handleAddImage}>Adicionar Banner</button>
            </Add>
        </Container>
    );
}

const Container = styled.div`
  width: 100%;
  max-width: 1200px; /* Define uma largura máxima para não esticar demais em telas grandes */
  margin: 0 auto; /* Centraliza o conteúdo */
  padding: 20px;
  background-color: #f5f5f5; /* Um fundo mais neutro */
  font-family: Arial, sans-serif;
`;

const Add = styled.div`
margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px; /* Espaço entre os inputs */
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Um leve sombreado para destaque */
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
  }

  button {
    padding: 10px 20px;
    background-color: #007BFF; /* Azul para chamar atenção */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: fit-content;
    font-size: 16px;
  }

  button:hover {
    background-color: #0056b3;
  }
`;

const ImageItem = styled.div`
  margin-bottom: 20px;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }

  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    margin-bottom: 10px;
  }

  button {
    padding: 8px 16px;
    background-color: #dc3545; /* Vermelho para exclusão */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }

  button:hover {
    background-color: #c82333;
  }
`;

const ChangeInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px; /* Espaço entre os inputs */
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Um leve sombreado para destaque */
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
  }

  button {
    padding: 10px 20px;
    background-color: #007BFF; /* Azul para chamar atenção */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: fit-content;
    font-size: 16px;
  }

  button:hover {
    background-color: #0056b3;
  }
`;