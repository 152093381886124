import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

export default function Notices() {
  const [hasAnimated, setHasAnimated] = useState(false);
  const containRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !hasAnimated) {
          setHasAnimated(true);
        }
      },
      { threshold: 0.1 }
    );

    if (containRef.current) {
      observer.observe(containRef.current);
    }

    return () => {
      if (containRef.current) {
        observer.unobserve(containRef.current);
      }
    };
  }, [hasAnimated]);

  const notices = [
    {
      img: "https://img.nsctotal.com.br/wp-content/uploads/2023/07/passaporte-brasileiro-e-o-etias-Marcelo-Camargo-Agencia-Brasil.jpg",
      title: "LEI JOCA:",
      text: "oferta de transporte de animais em cabine de avião é aprovada pela Câmara",
      link: "SAIBA MAIS"
    },
    {
      img: "https://img.nsctotal.com.br/wp-content/uploads/2023/07/passaporte-brasileiro-e-o-etias-Marcelo-Camargo-Agencia-Brasil.jpg",
      title: "LEI JOCA:",
      text: "oferta de transporte de animais em cabine de avião é aprovada pela Câmara",
      link: "SAIBA MAIS"
    },
    {
      img: "https://img.nsctotal.com.br/wp-content/uploads/2023/07/passaporte-brasileiro-e-o-etias-Marcelo-Camargo-Agencia-Brasil.jpg",
      title: "LEI JOCA:",
      text: "oferta de transporte de animais em cabine de avião é aprovada pela Câmara",
      link: "SAIBA MAIS"
    },
    {
      img: "https://img.nsctotal.com.br/wp-content/uploads/2023/07/passaporte-brasileiro-e-o-etias-Marcelo-Camargo-Agencia-Brasil.jpg",
      title: "LEI JOCA:",
      text: "oferta de transporte de animais em cabine de avião é aprovada pela Câmara",
      link: "SAIBA MAIS"
    },
    {
      img: "https://img.nsctotal.com.br/wp-content/uploads/2023/07/passaporte-brasileiro-e-o-etias-Marcelo-Camargo-Agencia-Brasil.jpg",
      title: "LEI JOCA:",
      text: "oferta de transporte de animais em cabine de avião é aprovada pela Câmara",
      link: "SAIBA MAIS"
    },
  ]

  return (
    <Container>
      <Text>
        <div>
          <h1>BLOG | #VOEnotícias</h1>
          <h2>Fique por dentro das principais notícias que acontecem no mundo.</h2>
        </div>
      </Text>
      <Contain ref={containRef} hasAnimated={hasAnimated}>
        <div>
          <img src='https://ecrie70.com.br/sistema/conteudos/imagem/g_66_0_1_20122023154657.jpg' alt='teste' />
          <p>Publicado em 12 de julho de 2024</p>
          <h2>TREM NO RS leva passageiros até a fronteira do Uruguai...</h2>
        </div>
        <div>
          <img src='https://ecrie70.com.br/sistema/conteudos/imagem/g_66_0_1_20122023154657.jpg' alt='teste' />
          <p>Publicado em 12 de julho de 2024</p>
          <h2>TREM NO RS leva passageiros até a fronteira do Uruguai...</h2>
        </div>
        <div>
          <img src='https://ecrie70.com.br/sistema/conteudos/imagem/g_66_0_1_20122023154657.jpg' alt='teste' />
          <p>Publicado em 12 de julho de 2024</p>
          <h2>TREM NO RS leva passageiros até a fronteira do Uruguai...</h2>
        </div>
        <div>
          <img src='https://ecrie70.com.br/sistema/conteudos/imagem/g_66_0_1_20122023154657.jpg' alt='teste' />
          <p>Publicado em 12 de julho de 2024</p>
          <h2>TREM NO RS leva passageiros até a fronteira do Uruguai...</h2>
        </div>
      </Contain>
    </Container>
  );
};

const Container = styled.div`
      width: 100%;
      height: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  margin-bottom: 100px;
  @media (max-width: 1100px){
    display: none;
  }
`;

const Contain = styled.div`
  width: 1100px;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  
  ${({ hasAnimated }) =>
    hasAnimated &&
    css`
      opacity: 1;
      transform: translateX(0);

      div {
        opacity: 0;
        transform: translateX(-30px);
        transition: transform 1.5s ease-out, opacity 1.5s ease-out;
      }

      div:nth-child(1) {
        opacity: 1;
        transform: translateX(0);
      }

      div:nth-child(2) {
        opacity: 1;
        transform: translateX(0);
      }

      div:nth-child(3) {
        opacity: 1;
        transform: translateX(0);
      }

      div:nth-child(4) {
        opacity: 1;
        transform: translateX(0);
      }

      div:nth-child(5) {
        opacity: 1;
        transform: translateX(0);
      }
    `}
  
  div {
    width: 250px;
    height: 220px;

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 250px;
      height: 220px;
    }

    p {
      font-family: "Montserrat", sans-serif;
      font-size: 10px;
      margin-top: 10px;
    }

    h2 {
      font-family: "Montserrat", sans-serif;
      font-weight: bold;
      margin-top: 10px;
    }
  }
`;
const Text = styled.div`
width: 1100px;
text-align: end;
display:flex;
justify-content: end;
div{
  width:300px;
}
h1{
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  margin-bottom: 15px;
}
h2{
  font-family: "Montserrat", sans-serif;
  color:#595957;
  margin-bottom: 20px;
}
`;
