import styled from "styled-components";
import Header from "../Components/InitialPage/Header";
import { useState } from "react";
import Footer from "../Components/InitialPage/Footer";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaWhatsapp } from "react-icons/fa";
import ContactSlogan from "../Components/InitialPage/ContactSlogan";
import { growFromLeft, slideIn } from "../Components/Animations/Animations";


export default function ContactPage() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            'service_d8eq3yh', // Substitua pelo seu service ID
            'template_09ufrju', // Substitua pelo seu template ID
            {
                from_name: formData.name,
                from_email: formData.email,
                phone: formData.phone,
                message: formData.message,
            },
            'FKhpG9hk-bwwOyuWx' // Substitua pelo seu user ID
        ).then((response) => {
            toast.success('Email enviado com sucesso!');
            setFormData({
                name: '',
                email: '',
                phone: '',
                message: '',
            }); // Resetando os campos após o envio
        }).catch((error) => {
            toast.error('Erro ao enviar o email. Tente novamente mais tarde.');
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <>
            <Header />
            <ContactSlogan />
            <Line></Line>
            <Container>
                <Contain>
                    <Message>
                        <Title>
                            <p>FICOU ALGUMA DÚVIDA?</p>
                            <p>ENTRE EM <strong>CONTATO</strong></p>
                        </Title>
                        <SubTitle>
                            <p>Nosso time está pronto para atender você!</p>
                            <p>Preencha os campos ao lado e solicite contato.<strong> Em pouco tempo algum de nossos
                                agente entrará em contato com você!</strong></p>
                        </SubTitle>
                        <Email>
                            <img src="https://static.vecteezy.com/system/resources/previews/021/454/516/original/email-confirmation-app-icon-email-icon-free-png.png" alt="emailCheck" />
                            <h2>voecheap@gmail.com</h2>
                            {/* <WhatsappIcon /> */}
                            {/* <a href="https://wa.me/5532984957373" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Whats>(32) 98495-7373</Whats>
                            </a>
                            <a href="https://wa.me/5532984054929" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Whats>(32) 98405-4929</Whats>
                            </a> */}
                            <a href="https://linktr.ee/voecheap" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black', marginTop: '10px', fontWeight: 'bold'}}>Linktree</a>
                        </Email>
                    </Message>
                    <StyleForm>
                        <Informations onSubmit={handleSubmit}>

                            <Input type="text" id="name" name="name" value={formData.name}
                                onChange={handleInputChange} placeholder="Escreva seu nome completo" required />


                            <Input type="text" id="email" name="email" value={formData.email}
                                onChange={handleInputChange} placeholder="Insira seu melhor e-mail" required />


                            <Input type="text" id="phone" name="phone" value={formData.phone}
                                onChange={handleInputChange} placeholder="Telefone para contato" required />


                            <InputMessage type="text" id="message" name="message" value={formData.message}
                                onChange={handleInputChange} placeholder="Escreva sua solicitação..." required />

                            <Centralize><Button type="submit" style={{ textDecoration: "none" }} ><h1>ENVIAR</h1></Button></Centralize>
                        </Informations>
                    </StyleForm>
                </Contain>
                <CustomToastContainer />
            </Container>
            <Footer />
        </>
    )
}

const CustomToastContainer = styled(ToastContainer)`
.Toastify__toast--success {
    background-color: #1f4586;  /* Verde para sucesso */
   margin-right: 60px;
   height: 100px;
   width: 300px;
}
.Toastify__toast--error {
    background-color: #f44336;  /* Vermelho para erro */
}
.Toastify__toast-body {
    font-family: "Montserrat", sans-serif;  /* Fonte personalizada */
    color: white;  /* Cor do texto */
}
`;

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const Message = styled.div`
animation: ${slideIn} 2.5s ease-out;
width: 600px;
margin-top: 30px;
margin-bottom: 30px;
@media(max-width:899px){
width: 100%;
margin-right: 20px;
}
`;

const Title = styled.div`
 font-family: "Montserrat", sans-serif;
 margin-top: 15px;
 font-size: 20px;
 margin-left: 30px;
 strong{
    font-weight: bold;
 }
 p{
    margin-top: 10px;
 }
 @media(min-width:899px){

}
`;

const SubTitle = styled.div`
 font-family: "Montserrat", sans-serif;
 margin-top: 10px;
margin-left: 30px;
 line-height: 1.3;
 strong{
    font-weight: bold;
 }
`;

const Email = styled.div`
 margin-left: 30px;
 width: 100%;
 a{
    font-family: "Montserrat", sans-serif;
 }
img{
    width: 55px;
    margin-left: -10px;
}
h2{
    font-family: "Montserrat", sans-serif;
}
@media(max-width:899px){
    display: flex;
    flex-direction: column;
    align-items: center;
margin-left: 0px;
}
`;

const Line = styled.div`
width: 100%;
height: 2px;
background-color: black;
`;

const Contain = styled.div`
width: 100%;
display: flex;
@media(max-width:899px){
flex-direction: column;
align-items: center;
justify-content: center;
}
`;

const StyleForm = styled.div`
width: 80%;
margin-top: 30px;
padding: 10px;
border-radius: 10px;
margin-bottom: 30px;
margin-right: 30px;
@media(max-width:899px){
margin-right: 00px;
}
`;

const Informations = styled.form`
display: flex;
flex-direction: column;
label{
    font-size: 20px;
    margin-left: 5px;
    color: white;
}
`;

const Centralize = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
`;

const Button = styled.button`
 border-radius: 15px;
 color: white;
 background-color: #1f4586;
 font-family: "Montserrat", sans-serif;
 height: 30px;
 width: 90px;
 border: none;
 margin-bottom:20px;
 cursor: pointer;
&:hover {
     background-color: rgba(1, 56, 129, 0.5);
}
`;

const Input = styled.input`
 height: 40px;
 margin-bottom: 10px;
 margin-top: 5px;
 border-radius: 20px;
 border: none;
 background-color: #688bc1;
 padding-left: 10px;
 transform-origin: left;
 animation: ${growFromLeft} 2.5s ease-out;
::placeholder{
    color: rgba(255, 255, 255, 0.7);
}
`;

const InputMessage = styled.input`
height: 200px;
border-radius: 30px;
margin-bottom: 10px;
 margin-top: 5px;
 border: none;
 background-color: #688bc1;
 position: relative;
 padding-bottom:150px;
 box-sizing: border-box;
 padding-left: 10px;
 transform-origin: left;
 animation: ${growFromLeft} 2.5s ease-out;
::placeholder{
    position: absolute;
    padding-top:20px;
        top: 0; 
        left: 0;
    color: rgba(255, 255, 255, 0.7);
    padding-left: 10px;
}
`;

const WhatsappIcon = styled(FaWhatsapp)`
  color: black;
  font-size: 45px;
  margin-top: 5px;
`;

const Whats = styled.h2`
margin-top: 5px;
`;
