import styled, { css, keyframes } from "styled-components";
import Header from "../Components/InitialPage/Header";
import TeamSlogan from "../Components/InitialPage/TeamCompnts/TeamSlogan";
import Footer from "../Components/InitialPage/Footer";
import { useEffect, useRef, useState } from "react";
import petHeart from "../Assets/petHeart.png";
import Paulo from "../Assets/Paulo.webp";
import Rodolpho from "../Assets/Rodolpho.webp";
import Bernardo from "../Assets/Bernardo.webp";
import Iago from "../Assets/Iago.webp";
import Giovanna from "../Assets/Giovanna.webp";
import Rafaela from "../Assets/Rafaela.webp";
import Img1 from "../Assets/SobreNosImg1.jpg";
import Img2 from "../Assets/SobreNosImg2.jpg";

const slideInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideInFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideInFromBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export default function TeamPage() {

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handlePlayVideo = () => {
    setIsVideoPlaying(true);
  };

  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [countersVisible, setCountersVisible] = useState(false);
  const suporteRef = useRef(null);
  const numerosRef = useRef(null);

  const team = [
    { id: 1, name: "Paulo Brandão", function: "Diretor/Vendedor", foto: Paulo },
    { id: 2, name: "Iago Mello", function: "Diretor/Vendedor", foto: Iago },
    { id: 3, name: "Rafaela Conde", function: "Jurídico", foto: Rafaela },
    { id: 4, name: "Giovanna Altomar", function: "Marketing", foto: Giovanna },
    { id: 5, name: "Bernardo Saar", function: "Vendedor", foto: Bernardo },
    { id: 6, name: "Rodolpho Buettel", function: "Desenvolvedor", foto: Rodolpho }
  ]

  useEffect(() => {
    if (countersVisible) {
      let start = 0;
      const target1 = 1000;
      const target2 = 500;
      const target3 = 100;
      const duration = 4000;
      const increment1 = target1 / (duration / 16);
      const increment2 = target2 / (duration / 16);
      const increment3 = target3 / (duration / 16);

      const updateCounter = () => {
        start += 1;


        if (start * increment1 < target1) {
          setCount(Math.ceil(start * increment1));
        } else {
          setCount(target1);
        }


        if (start * increment2 < target2) {
          setCount2(Math.ceil(start * increment2));
        } else {
          setCount2(target2);
        }


        if (start * increment3 < target3) {
          setCount3(Math.ceil(start * increment3));
        } else {
          setCount3(target3);
        }

        if (
          start * increment1 < target1 ||
          start * increment2 < target2 ||
          start * increment3 < target3
        ) {
          requestAnimationFrame(updateCounter);
        }
      };

      updateCounter();
    }
  }, [countersVisible]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (suporteRef.current) {
      observer.observe(suporteRef.current);
    }

    return () => {
      if (suporteRef.current) {
        observer.unobserve(suporteRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setCountersVisible(true); // Inicia a contagem quando visível
        }
      },
      { threshold: 0.1 }
    );

    if (numerosRef.current) {
      observer.observe(numerosRef.current);
    }

    return () => {
      if (numerosRef.current) {
        observer.unobserve(numerosRef.current);
      }
    };
  }, []);

  return (
    <>
      <Container>
        <Header />
        <TeamSlogan />
        <Text>
          <h1>QUEM ESTÁ POR TRÁS DA VOECHEAP?</h1>
          <h2>
            Fundada em 2022, a VOECHEAP nasceu da paixão em explorar o mundo de
            dois amigos, que se juntaram e mergulharam nesse universo das milhas
            aéreas. Através de muito estudo, pesquisa, experiência e formação de
            uma equipe completa e especializada, tornaram a empresa o que ela é
            hoje: referência em transparência, segurança, assistência e,
            principalmente, preços incomparáveis!
          </h2>
        </Text>
        <Images>
          <img
            src={Img1}
            alt="Imagem 1"
            loading="lazy"
          />
          <img
            src={Img2}
            alt="Imagem 2"
            loading="lazy"
          />
        </Images>
        <Team>
          <h1>CONHEÇA NOSSO TIME</h1>
          <Three>
            {team.slice(0, 3).map(member => (
             <div key={member.id} style={{ backgroundImage: `url(${member.foto})` }} loading="lazy">
                <h2>
                  {member.name}
                  <p>{member.function}</p>
                </h2>
              </div>
            ))}
          </Three>
          <Two>
            {team.slice(3).map(member => (
             <div key={member.id} style={{ backgroundImage: `url(${member.foto})` }} loading="lazy">
                <h2>
                  {member.name}
                  <p>{member.function}</p>
                </h2>
              </div>
            ))}
          </Two>
        </Team>
        <Video>
          <CurrentVideo>
            <iframe
              width="720"
              height="400"
              src="https://www.youtube.com/embed/HRkpMaKTmP4?&ab_channel=VOECHEAPTravelAgency"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </CurrentVideo>

        </Video>
        <Suporte ref={suporteRef}>
          <h1>QUAL O NOSSO DIFERENCIAL?</h1>
          <Diff isVisible={isVisible}>
            <div>
              <img src="https://cdn-icons-png.flaticon.com/512/3190/3190422.png" />
              <h2>Suporte on-line 24/7</h2>
              <p>Conte com a nossa equipe desde o momento da compra até a chegada no seu destino</p>
            </div>
            <div>
              <img src="https://cdn-icons-png.flaticon.com/512/5074/5074570.png" />
              <h2>Atendimento humanizado</h2>
              <p>Tenha segurança em todas as etapas da sua compra, atráves de um atendimento humanizado e transparente</p>
            </div>
            <div>
              <img src="https://img.freepik.com/vetores-premium/ilustracao-do-conceito-do-dia-mundial-de-conscientizacao-sobre-o-autismo-com-fundo-transparente_181203-28738.jpghttps://img.freepik.com/vetores-premium/fita-da-bandeira-do-orgulho-do-arco-iris-com-elementos-do-coracao-comunidade-lgbt-e-conceito-do-mes-do-orgulho-do-movimento_362366-125.jpg?w=740" />
              <h2>Suporte á PNE</h2>
              <p>Atendimento expecializado a pessoas com necessidades especiais</p>
            </div>
            <div>
              <img src={petHeart} />
              <h2>Viagens com pets</h2>
              <p>Desbrave o mundo com o seu pet sem preocupação</p>
            </div>
            <div>
              <img src="https://static.vecteezy.com/system/resources/previews/022/951/451/non_2x/stack-of-documents-icon-png.png" />
              <h2>Suporte pós-venda</h2>
              <p>Receba suporte e instruções a todo momento: check-in, adição de bagagem...</p>
            </div>
          </Diff>
        </Suporte>
        <Numeros ref={numerosRef}>
          <h1>CONTE COM A GENTE</h1>
          <Contadores>
            <div>
              <p>+ de</p>
              <Cont>{count}</Cont>
              <p>passagens vendidas</p>
            </div>
            <div>
              <p>+ de</p>
              <Cont>{count2}</Cont>
              <p>clientes satisfeitos</p>
            </div>
            <div>
              <p>+ de</p>
              <Cont>{count3}</Cont>
              <p>destinos desbravados</p>
            </div>
          </Contadores>
        </Numeros>
        <Footer />
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media(max-width:900px){
    align-items: center;
  }
`;

const Text = styled.div`
  margin-top: 50px;
  width: 370px;
  margin-left: 100px;
  @media(max-width:900px){
    margin-left: 30px;
    width: 300px;
  }
  h1 {
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-size: 20px;
    font-style: normal;
    line-height: 1.5;
  }
  h2 {
    animation: ${slideInFromLeft} 1.5s ease-out;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin-top: 20px;
    font-size: 17px;
    line-height: 1.5;
  }
`;

const Images = styled.div`
  position: absolute;
  top: 250px;
  width: 600px;
  right: 0px;
  @media(max-width:900px){
    display:none;
  }
  img {
    position: absolute;
    width: 250px;
    height: 300px;
    animation-duration: 2.5s;
    animation-fill-mode: both;
  }
  img:nth-child(1) {
    top: 0;
    left: 0;
    z-index: 15;
    animation-name: ${slideInFromLeft};
  }
  img:nth-child(2) {
    top: 100px; /* Ajuste conforme necessário */
    left: 200px; /* Ajuste conforme necessário */
    z-index: 15;
    animation-name: ${slideInFromRight};
  }
`;

const Team = styled.div`
  position: relative;
  margin-top: 150px;
  width: 100%;
  height: 180px;
  background-color: grey;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  @media(max-width:900px){
    width: 90%;
    border-radius: 0px;
    height: 100%;
    border-radius:10px;
  }
  h1 {
    position: absolute;
    top: -12px;
    left: 143px;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    color: black;
    font-size: 25px;
    @media(max-width:900px){
      position: inherit;
      width: 100%;
      left: 0px;
      font-size: 20px;
      text-align: center;
      margin-bottom: 0px;
    }
  }
`;

const Three = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
@media(max-width:900px){
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 35px;
}
  div {
    position: relative;
    margin-top: 30px;
    margin-bottom: 0px;
    width: 250px;
    height: 280px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 50%;
    overflow: hidden;
    @media(max-width:900px){
      margin-top: 0px;
      margin-bottom: 0px;
    }

    /* Adicionando uma camada escura */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0); /* Inicia transparente */
      transition: background-color 0.5s ease;
      z-index: 1;
    }

    h2 {
      position: relative;
      z-index: 2;
      width: 100%;
      background-color: #0c1a3b;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 50px;
      color: white;
      font-family: "Montserrat", sans-serif;
      font-size: 20px;
      font-weight: bold;
      transform: translateY(100%);
      transition: transform 0.5s ease;
      opacity: 0;
      p {
        font-weight: 100;
        font-size: 15px;
      }
    }

    &:hover {
      &::before {
        background-color: rgba(0, 0, 0, 0.5); /* Escurece ao passar o mouse */
      }

      h2 {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
`;

const Two = styled(Three)`
  /* Copia o estilo de Three */
`;
const Video = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 50%;
  width: 100%;
  height: 300px;
  margin-top: 500px;
  @media(max-width:900px){
    margin-top: 150px;
  }
`;

const CurrentVideo = styled.div`
margin-top: 20px;
  width: 720px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width:900px){
    width: 350px;
  }
  video {
    width: 100%;
    height: 100%;
  }
`;

const Suporte = styled.div`
width: 100%;
h1{
  font-family: "Montserrat", sans-serif;
  animation: ${slideInFromBottom} 1s ease-out;
  margin-left: 50px;
  margin-bottom: 30px;
  font-size: 25px;
  font-weight: bold;
  width: 220px;
  line-height: 1.2;
  @media(max-width:900px){
    margin-bottom: 0px;
    margin-left: 90px;
  }
}
`;

const Diff = styled.div`
  display: flex;
  justify-content: space-around;
width: 100%;
@media(max-width:900px){
flex-wrap: wrap;
justify-content: center;
align-items: center;
div{
  margin-left: 10px;
}
}
  div {
    font-family: "Montserrat", sans-serif;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;

    ${({ isVisible }) =>
    isVisible &&
    css`
        opacity: 1;
        transform: translateY(0);
      `}

    img {
      width: 50px;
      margin-bottom: 10px;
      @media(max-width:900px){
        width:30px;
      }
    }

    h2 {
      font-weight: bold;
      margin-bottom: 10px;
      @media(max-width:900px){
        font-size:12px;
      }
    }

    p {
      font-size: 12px;
      width: 170px;
      @media(max-width:900px){
        font-size:10px;
        width:150px;
      }
    }
  }
`;

const Numeros = styled.div`
 font-family: "Montserrat", sans-serif;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-top: 30px;
width: 100%;
h1{
  font-weight: bold;
  font-size: 25px;
  @media(max-width:900px){
    font-size: 20px;
  }
}
`;

const Contadores = styled.div`
display: flex;
align-items: center;
justify-content: space-around;
width: 800px;
margin-top: 20px;
p{
  font-size: 18px;
}

@media(max-width:900px){
 flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  p{
    font-size: 18px;
  }
}
}
`;

const Cont = styled.h2`
font-weight: bold;
font-size: 25px;
@media(max-width:900px){
  font-size: 18px;
}
`;