import styled from "styled-components";
import Pack from "../Pack";
import { useContext, useState } from "react";
import UserContext from "../../../contexts/ContextApi";
import { useModal } from "../../../contexts/ModelContext";

const PACKS_PER_PAGE = 4; // Quantidade de pacotes por página

export default function AllPackages() {
  const { packs } = useContext(UserContext);
  const { openModal } = useModal();
  const [selectedContinents, setSelectedContinents] = useState([]);
  const [filteredPacks, setFilteredPacks] = useState(packs);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(filteredPacks.length / PACKS_PER_PAGE);

  const handleSelectContinent = (continent) => {
    if (selectedContinents.length === 0) {
      setSelectedContinents([continent]);
    } else {
      setSelectedContinents((prevSelected) =>
        prevSelected.includes(continent)
          ? prevSelected.filter((item) => item !== continent)
          : [...prevSelected, continent]
      );
    }
  };

  const handleSearch = () => {
    if (selectedContinents.length === 0) {
      setFilteredPacks(packs);
    } else {
      const filtered = packs.filter((pack) =>
        selectedContinents.includes(pack.continent)
      );
      setFilteredPacks(filtered);
    }
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setSelectedContinents([]);
    setFilteredPacks(packs);
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * PACKS_PER_PAGE;
  const currentPacks = filteredPacks.slice(startIndex, startIndex + PACKS_PER_PAGE);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Title>CONFIRA NOSSAS PASSAGENS</Title>
      <ContentWrapper>
        <Filtro>
          <p>Filtros:</p>
          {["América do Sul", "América Central", "América do Norte", "Europa", "Ásia", "África", "Oceania"].map((continent) => (
            <Option key={continent}>
              <Checkbox
                checked={selectedContinents.includes(continent)}
                onChange={() => handleSelectContinent(continent)}
              />
              <Label>{continent}</Label>
            </Option>
          ))}
          <ButtonGroup>
            <Button onClick={handleSearch}>BUSCAR</Button>
            <Button onClick={handleResetFilters}>SEM FILTRO</Button>
          </ButtonGroup>
        </Filtro>
        <ButtonMobile onClick={handleSearch}>BUSCAR</ButtonMobile>
        <ButtonMobile onClick={handleResetFilters}>SEM FILTRO</ButtonMobile>
        <Container id="packages">
          {currentPacks.map((p, index) => (
            <Pack p={p} key={index} />
          ))}
        </Container>
      </ContentWrapper>
      <Pagination>
        <Button onClick={handlePreviousPage} disabled={currentPage === 1}>Anterior</Button>
        <span>Página {currentPage} de {totalPages}</span>
        <Button onClick={handleNextPage} disabled={currentPage === totalPages}>Próxima</Button>
      </Pagination>
      {/* <Cotacao>
        <p>Não encontrou a passagem que queria?</p>
      </Cotacao>
      <Button onClick={openModal}>Peça uma cotação personalizada</Button> */}
    </>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 1200px;
  margin: 20px auto;
  @media (max-width: 1200px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 85%;
  padding-bottom: 30px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  margin-top: 50px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  color: #535353;
  font-size: 30px;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 20px;
  }
`;

const Filtro = styled.div`
  font-family: "Montserrat", sans-serif;
  color: #535353;
  font-weight: bold;
  font-size: 15px;
  margin-left: -80px;
  border-right: 2px solid #535353; /* Adiciona a borda na lateral direita */
  height: 100%; /* Define a altura como 100% da viewport */
  padding-right: 20px; /* Adiciona espaçamento à direita do conteúdo */
  box-sizing: border-box; /* Inclui padding e border na largura total */
  p {
    margin-bottom: 10px;
  }
  @media (max-width: 768px) {
    border: none;
    margin-left:15px;
  }
`;


const Option = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 768px) {
   margin-bottom:5px;
   font-size: 12px;
  }
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #013881;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  &:checked {
    background-color: #013881;
  }
  @media (max-width: 768px) {
  width: 10px;
  height: 10px;
  }
`;

const Label = styled.span`
  font-family: "Montserrat", sans-serif;
  color: #535353;
  font-weight: bold;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const Button = styled.button`
  padding: 5px 10px;
  border-radius: 15px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  border: none;
  background-color: #798eb7;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(33, 68, 136, 1);
  }
  @media (max-width: 768px) {
   font-size:10px;
  }
`;

const ButtonMobile = styled.button`
  padding: 5px 10px;
  border-radius: 15px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  border: none;
  background-color: #798eb7;
  color: white;
  cursor: pointer;
  display: none;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(33, 68, 136, 1);
  }
`;

const Cotacao = styled.div`
  width: 80%;
  text-align: center;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 20px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  background-color: #013881;
  color: white;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
  font-family: "Montserrat", sans-serif;
  color: #535353;
`;