import styled from "styled-components";
import fundo from "../../Assets/fundo.png";
import fumaca from "../../Assets/efeito.png";
import { growFromLeft, slideIn } from "../Animations/Animations";
import { useEffect, useState } from "react";
import axios from "axios";
import CapaFundo from "../../Assets/700.png";
import foto1 from "../../Assets/1.png";
import foto2 from "../../Assets/2.png";

export default function Info() {

    // Fazendo a requisição para a API local
    // useEffect(() => {
    //     const fetchInfo = async () => {
    //         try {
    //             const response = await axios.get("http://localhost:5000/info"); // Substitua pela sua rota de API
    //             setInfo(response.data[0].imagePrincipal); // Atualiza o estado com as imagens da API
    //             setImg1(response.data[0].imgSec);
    //             setImg2(response.data[0].imgTerc)
    //         } catch (error) {
    //             console.error("Erro ao buscar imagens:", error);
    //         }
    //     };

    //     fetchInfo();
    // }, []);

    return (
        <>
            <Container fundo={fundo}>
                <Efeito src={fumaca} />
                {/* <EfeitoBottom src={fumaca} /> */}
                <Text>
                    <h1>CONHEÇA A AGÊNCIA QUE VAI REALIZAR SEUS SONHOS</h1>
                    <h2>
                        Entendemos que a jornada começa antes mesmo do avião decolar. Por isso, nossa equipe apaixonada e dedicada trabalha incansavelmente
                        para oferecer as melhores opções de passagens aéreas, garantindo que cada viagem seja uma experiência inesquecível!
                    </h2>
                    <DivWrapper>
                    <Div1 bgImage={foto1} />
                    <Div2 bgImage={foto2} />
                    </DivWrapper>
                </Text>
                <Images bgImage={CapaFundo}>
                    <img src={foto1} alt="Imagem 1" />
                    <img src={foto2} alt="Imagem 2" />
                </Images>
            </Container>
        </>
    );
};

const Container = styled.div`
    width: 100%;
    height: 500px;
    display: flex;
    position: relative;
    background-image: url(${props => props.fundo});
    z-index: 15;
    @media(max-width:600px){
        height: 700px;
    }
`;

const Efeito = styled.img`
    position: absolute;
    right: 0px;
    top: -300px;
    z-index: 10;
    width: 1000px;
    @media(max-width:600px){
display: none;
}
@media (min-width: 768px) and (min-height: 1024px) {
  display: none;
}
`;

const EfeitoBottom = styled.img`
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    width: 1000px;
    transform: rotate(180deg);
`;

const Text = styled.div`
animation: ${slideIn} 1.5s ease-out;
    margin-top: 50px;
    width: 300px;
    margin-left: 100px;
    @media(max-width:600px){
        margin-left: 50px;
    }
    h1 {
        font-family: "Oswald", sans-serif;
        font-optical-sizing: auto;
        font-weight: bold;
        font-size: 20px;
        font-style: normal;
        line-height: 1.5;
    }
    h2 {
        font-family: "Montserrat", sans-serif;
        margin-top: 20px;
        font-size: 17px;
        line-height: 1.5;
    }
`;

const Images = styled.div`
    position: absolute;
    width: 700px;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 50%;
    right: 0px;
    background-image: url(${props => props.bgImage});
    @media (min-width: 768px) and (min-height: 1024px) {
   background-image: none;
   width: 250px;
   height: 0px;
}
    img {
        transform-origin: left;
        animation: ${growFromLeft} 1.5s ease-out;
        border-radius: 20px;
        position: absolute;
        width: 250px;
        margin-top: 50px;
        height: 330px;
        @media (min-width: 768px) and (min-height: 1024px) {
        width: 180px;
        height: 230px;
}
    }
    img:nth-child(1) {
        top: 0;
        left: 0;
        z-index: 15;
        
    }
    img:nth-child(2) {
        top: 100px; /* Ajuste conforme necessário */
        left: -200px; /* Ajuste conforme necessário */
        z-index: 15;
        @media (min-width: 768px) and (min-height: 1024px) {
   left: -100px;
}
    }
    @media(max-width:600px){
        display: none;
}   
`;

const DivWrapper = styled.div`
    position: relative;
    margin-top: 200px; /* Ajuste conforme necessário */
    @media(min-width: 600px){
        display: none;
    }
`;

const Div1 = styled.div`
    width: 200px;
    height: 150px;
    background-color: blue;
    position: absolute;
    bottom: -100px;
    left: -20px;
    border-radius: 10px;
    transform: rotate(-100deg);
    z-index: 11;
    overflow: hidden; /* Evita que a imagem ultrapasse a div */

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 150%;
        height: 150%;
        background-image: url(${props => props.bgImage});
        /* background-size: ; */
        background-position: center 100%;
        transform: translate(-50%, -50%) rotate(100deg) scale(0.98); /* Centraliza e expande a imagem */
    }
`;

const Div2 = styled.div`
    width: 200px;
    height: 150px;
    background-color: green;
    position: absolute;
    bottom: -30px;
    left: 105px;
    border-radius: 10px;
    transform: rotate(100deg);
    z-index: 10;
    overflow: hidden; /* Evita que a imagem ultrapasse a div */

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 150%;
        height: 150%;
        background-image: url(${props => props.bgImage});
        /* background-size: ; */
        background-position: center 100%;
        transform: translate(-50%, -50%) rotate(-100deg) scale(0.98); /* Centraliza e expande a imagem */
    }
`;
