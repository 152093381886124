import styled, { keyframes } from "styled-components"
import React, { useEffect, useState } from 'react';
import banner from "../../Assets/TesteBanner.png";
import torre2 from "../../Assets/TorreModelo2.png";
import banner2 from "../../Assets/banner.png";
import capaContato from "../../Assets/CapaContato.jpg";

export default function ContactSlogan() {

  // const images = [
  //   {
  //     url: ' "https://encurtador.com.br/mAGS5"',
  //     texts: {
  //       large: 'PORTUGAL',
  //       medium: 'passagem de <strong>ida</strong>',
  //       small: 'a partir de:',
  //     },
  //     price: "R$2650"
  //   },
  //   {
  //     url: `${banner}`,
  //     texts: {
  //       large: 'ORLANDO',
  //       medium: 'passagem de <strong>ida</strong> e <strong>volta</strong>',
  //       small: 'a partir de:',
  //     },
  //     price: "R$3050"
  //   },
  //   {
  //     url: `${torre2}`,
  //     texts: {
  //       large: 'PARIS',
  //       medium: 'passagem de <strong>ida</strong> e <strong>volta</strong>',
  //       small: 'a partir de:',
  //     },
  //     price: "R$4500"
  //   },
  // ];

  // const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [images.length]);

  // const handleCircleClick = (index) => {
  //   setCurrentImageIndex(index);
  // };

  // const texts = [
  //   "suporte 24 *",
  //   "segurança para comprar e viajar",
  //   "até 40% de desconto",
  //   "em até 12x no cartão de crédito",
  //   "suporte 24 *",
  //   "segurança para comprar e viajar",
  //   "até 40% de desconto",
  //   "em até 12x no cartão de crédito",
  //   "suporte 24 *",
  //   "segurança para comprar e viajar",
  //   "até 40% de desconto",
  //   "em até 12x no cartão de crédito",
  //   "suporte 24 *",
  //   "segurança para comprar e viajar",
  //   "até 40% de desconto",
  //   "em até 12x no cartão de crédito",
  // ];

  // const duplicatedTexts = [...texts, ...texts];

  return (
    <Container>
      <Image capa={capaContato}>
        <SaibaMais>CONTATO</SaibaMais>
      </Image>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  z-index: 20;
  @media(min-width:900px){
    height: 200px;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.capa});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Sombra escura com 50% de opacidade */
    z-index: 1; /* Certifique-se de que a sombra está abaixo do conteúdo */
}
  /* Para garantir que o conteúdo fique acima da sombra */
& > * {
    position: relative;
    z-index: 2;
  }
`;

const TextArea = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
  h1{
  font-family: "Anton", sans-serif;
  font-weight: #fbfcfd;
  font-style: normal;
  font-size: 50px;
  color: white;
  }
  h2{
    margin-top: -30px;
    font-size: 100px;
    color: rgba(255, 255, 255, 0.5);
    font-family: "Merriweather", serif;
  font-style: normal
  }
  div{
    width: 100%;
    text-align: end;
    font-family: "Anton", sans-serif;
  font-weight: #fbfcfd;
  font-style: normal;
  color: white;
  }
`;

const SaibaMais = styled.div`
position: absolute;
top: 150px;
font-family: "Montserrat", sans-serif;
font-weight: bold;
color:white;
`;


const Circles = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 10;
`;
const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => (props.active ? 'white' : 'transparent')};
  border: 1px solid white;
  transition: background-color 0.3s;
`;
const scroll = keyframes`
   0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
`;