import styled from "styled-components";
import banner2 from "../../../Assets/banner.png";
import capaSobreNos from "../../../Assets/DSC_0943.jpg";


export default function TeamSlogan() {
    return (<>
        <Container>
            <Image capa={capaSobreNos}>
                <SaibaMais>SOBRE NÓS</SaibaMais>
            </Image>
        </Container>
    </>)
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  z-index: 20;
  @media(min-width:900px){
    height: 200px;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.capa});
  background-size: 40%;
  /* background-repeat: no-repeat; */
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Sombra escura com 50% de opacidade */
    z-index: 1; /* Certifique-se de que a sombra está abaixo do conteúdo */
}
  /* Para garantir que o conteúdo fique acima da sombra */
& > * {
    position: relative;
    z-index: 2;
  }
  @media (max-width: 760px) {
    background-size: 100%;
    background-repeat: no-repeat;
}
`;

const SaibaMais = styled.div`
position: absolute;
top: 150px;
font-family: "Montserrat", sans-serif;
font-weight: bold;
color:white;
`;

// const Text = styled.div`
// margin-top: 50px;
// width: 370px;
// margin-left: 100px;
// h1{
//     font-family: "Oswald", sans-serif;
//   font-optical-sizing: auto;
//   font-weight: bold;
//   font-size: 20px;
//   font-style: normal;
//   line-height: 1.5;
// }
// h2{
//     font-family: "Montserrat", sans-serif;
//     font-weight: 500;
//     margin-top: 20px;
//     font-size: 17px;
//     line-height: 1.5;
// }
// `;

// const Images = styled.div`
// position: absolute;
// top: 250px;
// width: 600px;
// right: 0px;
// background-color: red;
// img{
//     position: absolute;
//     width: 250px;
//     height: 300px;
// }
// img:nth-child(1) {
//     top: 0;
//     left: 0;
//     z-index: 15;
// }
// img:nth-child(2) {
//     top: 100px; /* Ajuste conforme necessário */
//     left: 200px; /* Ajuste conforme necessário */
//     z-index: 15;
// }
// `;

// const Team = styled.div`
// position: relative;
// margin-top:150px;
// width: 1000px;
// height: 180px;
// background-color: grey;
// border-top-right-radius: 30px;
// border-bottom-right-radius: 30px;
// h1{
//     position: absolute;
//     top: -12px;
//     left: 143px;
//     font-family: "Montserrat", sans-serif;
// font-weight: bold;
// color: black;
// font-size: 25px;
// }
// `;

// const Three = styled.div`
// display: flex;
// justify-content: center; /* Centraliza os itens */
// gap: 50px; /* Ajusta o espaçamento entre os itens */
// div{
//     margin-top: 30px;
//     margin-bottom: 30px;
//     width:200px;
//     height: 220px;
//     background-color: red;
// }
// `;

// const Two = styled.div`
// display: flex;
// justify-content: center; /* Centraliza os itens */
// gap: 50px; /* Ajusta o espaçamento entre os itens */
// div{
//     margin-top: 0px;
//     margin-bottom: 100px;
//     width:200px;
//     height: 220px;
//     background-color: red;
// }
// `;

// const Video = styled.div`
// width: 100%;
// height: 200px;
// background-image: url("https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSU82t9q67YnfKLR0OMdoV8hGhvrJGxAKxnalKyR_s3bcnYmXiM");
// `