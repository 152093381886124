import styled from "styled-components"
import React from 'react';
import banner2 from "../../Assets/banner.png";
import capaPassagens from "../../Assets/CapaPassagens.png";

export default function PackSlogan() {

  return (
    <Container>
      <Image capa={capaPassagens}>
        <SaibaMais>PASSAGENS</SaibaMais>
      </Image>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  z-index: 20;
  @media(min-width:900px){
    height: 200px;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.capa});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
}
& > * {
    position: relative;
    z-index: 2;
  }
`;

const SaibaMais = styled.div`
position: absolute;
top: 150px;
font-family: "Montserrat", sans-serif;
font-weight: bold;
color:white;
`;
