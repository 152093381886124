import { createContext, useState } from "react";
import orla from "../Assets/Orlando.png";
import torre2 from "../Assets/TorreModelo2.png";

const UserContext = createContext();

function UserProvider({ children }) {
    const [packSelect, setPackSelect] = useState("");
    const [packgeId, setPackId] = useState(0);
    const [lastPosts, setLastPosts] = useState([]);
    const [images, setImages] = useState([
        {
            id: 1,
            url: 'https://encurtador.com.br/mAGS5',
            texts: 'PORTUGAL',
            price: "R$2650"
        },
        {
            id: 2,
            url: orla,
            texts: 'ORLANDO',
            price: "R$3050"
        },
        {
            id: 3,
            url: torre2,
            texts: 'PARIS',
            price: "R$4500"
        },
    ]);

    const updateImageUrl = (id, newUrl) => {
        const newImages = images.map(image =>
            image.id === id ? { ...image, url: newUrl } : image
        );
        setImages(newImages);
    };

    const addImage = (newImage) => {
        const newId = images.length > 0 ? Math.max(images.map(image => image.id)) + 1 : 1;
        setImages([...images, { ...newImage, id: newId }]);
    };
    const removeImage = (id) => {
        const newImages = images.filter(image => image.id !== id);
        setImages(newImages);
    };

    const packs = [
        { id: 1, destiny: "PORTUGAL", continent: "Europa", image: "https://encurtador.com.br/mAGS5", price: "R$2.650/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 2, destiny: "ORLANDO", continent: "América do Norte", image: "https://www.visiteosusa.com.br/sites/default/files/styles/hero_l/public/images/hero_media_image/2021-09/95e7d963-79dc-46f5-9388-4a128a086b2a.jpeg?h=58c8a5e7&itok=c4aYEBPF", price: "R$3.050/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagens de ida e volta" },
        { id: 6, destiny: "NOVA YORK", continent: "América do Norte", image: "https://www.passagenspromo.com.br/blog/wp-content/uploads/2019/05/pontos-turisticos-de-nova-york.jpg", price: "R$3.000/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagens de ida e volta" },
        { id: 19, destiny: "MIAMI", continent: "América do Norte", image: "https://trendlink.com.br/TxdQw", price: "R$2.890/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 18, destiny: "ROMA", continent: "Europa", image: "https://abrir.link/AApIl", price: "R$3.850/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 4, destiny: "MADRID", continent: "Europa", image: "https://encurtador.com.br/gszBU", price: "R$4.000/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso 02 bagagem de 23kg (porão)", ticket: "Passagens de ida e volta" },
        { id: 15, destiny: "BOSTON", continent: "América do Norte", image: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcR1q5Qgl3ciIiob53XeKzF2PPQ1pPkm83ZkqX-A434XJSF35A2u", price: "R$3.000/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 21, destiny: "CHICAGO", continent: "América do Norte", image: "https://assets.cityexperiences.com/wp-content/uploads/2022/11/chicago.jpg", price: "R$2.800/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 5, destiny: "ORLANDO - EXECUTIVA (ASSENTO CAMA)", continent: "América do Norte", image: "https://encurtador.com.br/oILU0", price: "R$8.490/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagens de ida e volta" },
        { id: 9, destiny: "DOHA", continent: "Ásia", image: "https://ogimg.infoglobo.com.br/in/24126172-437-0e9/FT1086A/GettyImages-504437964.jpg", price: "R$3.900/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 26, destiny: "LONDRES", continent: "Europa", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRg71zsIA7UfhoeawhrGb4zC9w7UKnBT0LZeATb8PJau221vLvi", price: "R$4.200/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 3, destiny: "PARIS", continent: "Europa", image: "https://encurtador.com.br/goEU1", price: "R$4.500/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagens de ida e volta" },
        { id: 8, destiny: "ATENAS", continent: "Europa", image: "https://i0.wp.com/sejoganoroteiro.com.br/wp-content/uploads/2020/04/Partenon2-e1587408502713.jpg?fit=562%2C405&ssl=1", price: "R$4.700/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 17, destiny: "MILÃO", continent: "Europa", image: "https://abrir.link/jUoyW", price: "R$3.850/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 25, destiny: "TORONTO", continent: "América do Norte", image: "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQUca4mMJptm8GlzOZs5T_sRitq4FjsTXg9wb__O-Zjyh-EXcJ9", price: "R$1.290/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 30, destiny: "AMSTERDAM", continent: "Europa", image: "https://abrir.link/BGFfC ", price: "R$4.500/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 11, destiny: "DUBLIN", continent: "Europa", image: "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcS9beePMViCQH4RrCjLU_5MiX7RpF2QuFfLwesoiAG22ENxoTMJ", price: "R$2.950/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 7, destiny: "ZURIQUE", continent: "Europa", image: "https://www.estudarfora.org.br/wp-content/uploads/2019/05/zurique.jpg.webp", price: "R$4.200/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 10, destiny: "MONTREAL", continent: "América do Norte", image: "https://encurtador.com.br/uzxbN", price: "R$2.950/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 12, destiny: "BOGOTÁ", continent: "América do Sul", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdLOaxMx_whOB9vLpT1NgImi7W8G16U4W6Ia1azMedesG21wCX", price: "R$2.000/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 13, destiny: "JOANESBURGO", continent: "África", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpy489Nodge4qWt2eZce12K6YWUn4_aG92VuBImOLRerQpLBbi", price: "R$3.200/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso 02 bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 14, destiny: "JOANESBURGO - EXECUTIVA", continent: "África", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpy489Nodge4qWt2eZce12K6YWUn4_aG92VuBImOLRerQpLBbi", price: "R$8.900/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 16, destiny: "MANAUS", continent: "América do Sul", image: "https://abrir.link/rgPzr", price: "R$1.560/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 20, destiny: "ISTAMBUL", continent: "Europa", image: "https://img.freepik.com/fotos-premium/a-torre-da-torre-galata-e-uma-torre-medieval_851826-531.jpg?w=360", price: "R$4.700/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 22, destiny: "FRANKFURT", continent: "Europa", image: "https://live.staticflickr.com/65535/52231971605_dde38f48eb_b.jpg", price: "R$4.700/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 23, destiny: "URUGUAI", continent: "América do Sul", image: "https://i.pinimg.com/originals/54/16/05/541605be4805092419d708af1c422528.jpg", price: "R$2.800/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 24, destiny: "SALVADOR", continent: "América do Sul", image: "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTjGEq1OhdpnYzP--Th3e09SSF3EmkVx6UQQZBALofrgBcPWu6B", price: "R$4.700/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 27, destiny: "NATAL", continent: "América do Sul", image: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Morro_do_Careca_-_Ponta_Negra%2C_Natal-RN.jpg", price: "R$1.050/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 28, destiny: "LOS ANGELES", continent: "América do Norte", image: "https://encurtador.com.br/hlNCB", price: "R$2.900/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 29, destiny: "MACEIÓ", continent: "América do Sul", image: "https://vivoportodegalinhas.com/wp-content/uploads/2024/01/Caminho-de-Moises.jpeg", price: "R$1.300/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 31, destiny: "PERU", continent: "América do Sul", image: "https://abrir.link/KSgZI", price: "R$1.990/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 32, destiny: "SANTIAGO", continent: "América do Sul", image: "https://abrir.link/ZnmHB", price: "R$1.550/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 33, destiny: "BUENOS AIRES", continent: "América do Sul", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjxZSWTo_37nZ_7ry3WKSnY-m_rKHrTK63msY17ZnsYPvLa-E4", price: "R$1.500/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 34, destiny: "FLORIANÓPOLIS", continent: "América do Sul", image: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSLHE7oJw6U0tB6nHj5WrJ60TUjh4mRo5M71UL7qqwhqjmooXuZ", price: "R$450/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 35, destiny: "PUNTA CANA", continent: "América Central", image: "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQjmR0wo6IuYC6uTY3_BpVUC7an82RriDy3bZzw0N6SZ8Cow-0r", price: "R$2.250/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 36, destiny: "GRAMADO", continent: "América do Sul", image: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcS4chJnaFvVU8EKJjhOeA4Ej1bVg2ohcO3khjLHj7m_T_GjibGI", price: "R$690/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" },
        { id: 37, destiny: "BARCELONA", continent: "Europa", image: "https://encurtador.com.br/62hNo", price: "R$4.200/pessoa", support: "Auxílio pós-venda (check-in)", bilhete: "Cartão de embarque via Whatsapp", departureTax: "Taxa de embarque inclusa", baggage: "Incluso bagagem de 23kg (porão)", ticket: "Passagem só de ida" }
    ];

    return (
        <UserContext.Provider value={{
            packSelect, setPackSelect, packgeId, setPackId,
            lastPosts, setLastPosts, packs, images, updateImageUrl,
            addImage, removeImage
        }} >
            {children}
        </UserContext.Provider>
    );
}

export default UserContext;

export { UserProvider };