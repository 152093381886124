import styled from "styled-components";
import logo from "../../Assets/logoBranca.png";
import { useContext, useState, useEffect } from "react";
import { FaBars, FaSearch } from 'react-icons/fa';
import UserContext from "../../contexts/ContextApi";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { growFromLeft, growFromTop } from "../Animations/Animations";

export default function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { packs } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [display, setDisplay] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileSearchOpen, setMobileSearchOpen] = useState(false);

  // const sidebarRef = useRef(null); // Ref para a sidebar
  
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
  //       setMenuOpen(false); // Fecha a sidebar se o clique for fora dela
  //     }
  //   };

  //   // Adiciona o evento de clique
  //   document.addEventListener('click', handleClickOutside);
    
  //   // Remove o evento de clique quando o componente é desmontado
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleSearch = () => {
    setIsOpen(!isOpen);
    setDisplay(isOpen ? "" : "BUSCAR PASSAGENS");
  };

  const filteredPacks = packs.filter(pack =>
    pack.destiny.toUpperCase().includes(searchTerm.toUpperCase())
  );

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleMobileSearch = () => { // Função para alternar pesquisa móvel
    setMobileSearchOpen(!isMobileSearchOpen);
    setSearchTerm(''); // Limpar termo de pesquisa quando abrir
  };

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  // Temporizador para fechar o submenu
  let submenuCloseTimeout;

  const handleMouseEnter = () => {
    // Cancela o temporizador de fechamento
    clearTimeout(submenuCloseTimeout);
    setIsSubMenuOpen(true);
  };

  const handleMouseLeave = () => {
    // Inicia o temporizador para fechar o submenu
    submenuCloseTimeout = setTimeout(() => {
      setIsSubMenuOpen(false);
    }, 200); // 200ms de atraso, ajuste conforme necessário
  };

  return (
    <>
      <Head isScrolled={isScrolled}>
        <MenuButton onClick={toggleMenu}>
          <IconContainer>
            <FaBars style={{ width: '100%', height: '100%', color: "white" }} />
          </IconContainer>
        </MenuButton>
        <Link to="/"><Logo src={logo} alt="logo" /></Link>
        <OptionsHeader>
          <StyledLink to="/"><Text>HOME</Text></StyledLink>
          <Text
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <StyledLink to="/allpacks"><Text>PASSAGENS</Text></StyledLink>
            {isSubMenuOpen && (
              <SubMenu
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <StyledLinkSubMenu to="/allpacks">Ver tudo</StyledLinkSubMenu>
                <StyledLinkSubMenu to="/filtredpacks/Europa">Europa</StyledLinkSubMenu>
                <StyledLinkSubMenu to="/filtredpacks/América do Sul">América do Sul</StyledLinkSubMenu>
                <StyledLinkSubMenu to="/filtredpacks/América do Norte" style={{ paddingLeft: "5px", paddingRight: "5px" }}>América do Norte</StyledLinkSubMenu>
                <StyledLinkSubMenu to="/filtredpacks/Ásia">Ásia</StyledLinkSubMenu>
                <StyledLinkSubMenu to="/filtredpacks/África">África</StyledLinkSubMenu>
              </SubMenu>
            )}
          </Text>
          <StyledLink to="/teampage"><Text>SOBRE NÓS</Text></StyledLink>
          <StyledLink to="/contact"><Text>CONTATO</Text></StyledLink>
          <StyledLink to="/faq"><Text>FAQ</Text></StyledLink>
          <SearchContainer>
            {isOpen && (
              <SearchInput
                display={display}
                type="text"
                value={searchTerm}
                placeholder={display}
                onChange={handleSearchChange}
                autoFocus
              />
            )}
            <SearchIcon onClick={toggleSearch} />
            {searchTerm && (
              <SearchResults>
                {filteredPacks.map(pack => (
                  <StyledLink key={pack.id} to={`/uniquepack/${pack.id}`}>
                    <Text style={{ color: "black" }}>{pack.destiny}</Text>
                  </StyledLink>
                ))}
              </SearchResults>
            )}
          </SearchContainer>
        </OptionsHeader>
        <SearchIconMobile onClick={toggleMobileSearch} />
        {isMobileSearchOpen && (
          <MobileSearchContainer>
            <SearchInput
              type="text"
              value={searchTerm}
              placeholder="Buscar passagens"
              onChange={handleSearchChange}
              autoFocus
            />
            {searchTerm && (
              <SearchResults>
                {filteredPacks.map(pack => (
                  <StyledLink key={pack.id} to={`/uniquepack/${pack.id}`}>
                    <Text style={{ color: "black" }}>{pack.destiny}</Text>
                  </StyledLink>
                ))}
              </SearchResults>
            )}
          </MobileSearchContainer>
        )}
      </Head>
      <Sidebar isOpen={isMenuOpen}>
        <StyledLink to="/"><Text>Home</Text></StyledLink>
        <DropdownContainer>
          <DropdownHeader onClick={toggleDropdown}>
            <Text>Destinos</Text>
            {isDropdownOpen ? <FaChevronUpIcon /> : <FaChevronDownIcon />}
          </DropdownHeader>
          {isDropdownOpen && (
            <DropdownMenu>
              <StyledLink to="/allpacks">Ver Tudo</StyledLink>
              <StyledLink to="/filtredpacks/EUROPA">Europa</StyledLink>
              <StyledLink to="/filtredpacks/AMÉRICA DO SUL">América do Sul</StyledLink>
              <StyledLink to="/filtredpacks/AMÉRICA DO NORTE">América do Norte</StyledLink>
              <StyledLink to="/filtredpacks/ÁSIA">Ásia</StyledLink>
              <StyledLink to="/filtredpacks/ÁFRICA">África</StyledLink>
            </DropdownMenu>
          )}
        </DropdownContainer>
        <StyledLink to="/teampage"><Text>Sobre Nós</Text></StyledLink>
        <StyledLink to="/contact"><Text>Contato</Text></StyledLink>
        <StyledLink to="/faq"><Text>FAQ</Text></StyledLink>
      </Sidebar>
    </>
  );
}

const Head = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: ${({ isScrolled }) => (isScrolled ? '#0c1a3b' : 'transparent')};
  border-bottom: 1px solid white;
  position: fixed;
  top: 0;
  z-index: 1000; /* Ensure Head is above Sidebar */
  transition: background-color 0.3s ease;
  @media(max-width: 600px) {
    background-color: #0c1a3b;
    width: 100%;
    justify-content: space-around;
  }
  @media (min-width: 768px) and (min-height: 1024px) {
   background-color: #0c1a3b;
   width: 100%;
}
`;

const Logo = styled.img`
  cursor: pointer;
  width: 180px;
  padding-left: 100px;
  @media(max-width: 600px) {
    width: 130px;
    padding-left: 0px;
  }
  @media (min-width: 768px) and (min-height: 1024px) {
   padding-left: 30px;
}
`;

const OptionsHeader = styled.div`
  display: flex;
  width: 800px;
  justify-content: space-evenly;
  position: relative;
  @media(max-width: 750px) {
    display: none;
  }
`;

const MenuButton = styled.div`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  @media(min-width: 750px) {
    display: none;
  }
  @media(max-width: 600px) {
    color: white;
  }
`;

const IconContainer = styled.div`
  flex-grow: 1;
  width: 30px; 
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 2px;
  background-color: transparent;
  color: white;
  ::placeholder {
    color: white;
    font-family: "Montserrat", sans-serif;
  }
`;

const SearchIcon = styled(FaSearch)`
  margin-left: -20px;
  cursor: pointer;
  color: white;
`;

const SearchIconMobile = styled(FaSearch)`
  color: white;
  @media(min-width: 600px) {
    display: none;
  }
`;

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px;
  background: white;
  z-index: 20;
  text-align: center;
  border-radius: 5px;
  padding: 0px;
`;

const Text = styled.h1`
  color: white;
  text-decoration: none;
  margin: 1rem 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
  @media(max-width: 600px) {
    color: white;
  }
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  z-index: 20;
  text-align: flex-start;
  border-radius: 5px;
  padding-left: 5px;
  color: white;
  background-color: #0c1a3b;
  animation: ${growFromTop} 0.5s ease;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  margin: 1rem 0;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledLinkSubMenu = styled(Link)`
text-decoration: none;
  color: inherit;
  margin: 1rem 0;
  font-family: "Montserrat", sans-serif;
  animation: ${growFromLeft} 0.5s ease-out;
  &:hover {
    text-decoration: underline;
  }
`;

const Sidebar = styled.div`
  left: 0;
  width: 200px;
  height: 160px; /* Adjust height to account for the Head */
  background-color: #0c1a3b;
  color: white;
  transform: translateY(${({ isOpen }) => (isOpen ? '0' : '-100%')});
  transition: transform 0.3s ease;
  z-index: 999; /* Ensure Sidebar is below Head */
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  position: fixed; /* Ensure Sidebar is fixed */
  top: 50px; /* Position Sidebar below the Head */
  @media(min-width:600px){
    display: none;
  }
`;

const FaChevronDownIcon = styled(FaChevronDown)`
  color: white;
`;

const FaChevronUpIcon = styled(FaChevronUp)`
  color: white;
`;

const DropdownContainer = styled.div`
  margin: 1rem 0;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -13px;
  margin-top: -13px;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #0c1a3b; /* Change text color on hover */
  }
`;

const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  background-color: #0c1a3b;
  border-radius: 5px;
  padding: 10px;
`;

const MobileSearchContainer = styled.div`
  position: absolute;
  top: 50px; /* Ajuste conforme necessário */
  width: 100%;
  background-color: #0c1a3b;
  color: white;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
`;
