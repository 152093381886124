import React, { useState } from "react";
import styled from "styled-components";

const Contain = styled.div`
  background-color: #839CC2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormContainer = styled.div`
  padding: 20px;
  width: 51%;
`;

const FormHeading = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  b {
    color: #F29755;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const Input = styled.input`
  width: 97.3%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 20px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const SubmitButton = styled.button`
  background-color: #0C1A3B;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: black;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export default function OpenForm() {
    const [formData, setFormData] = useState({
        name: "",
        email: "", // Adicionando o campo de email
        departureCity: "",
        finalCity:"",
        passengers: "",
        departureDate: "",
        returnDate: "",
        baggage: false,
        flexData: false
    });

    const [isFormValid, setIsFormValid] = useState(false);

    // Função para formatar a data enquanto o usuário digita
    const formatDate = (value) => {
        // Remove tudo que não for número
        const cleanValue = value.replace(/\D/g, "");

        // Insere as barras "/" no lugar correto
        if (cleanValue.length <= 2) {
            return cleanValue;
        } else if (cleanValue.length <= 4) {
            return `${cleanValue.slice(0, 2)}/${cleanValue.slice(2)}`;
        } else {
            return `${cleanValue.slice(0, 2)}/${cleanValue.slice(2, 4)}/${cleanValue.slice(4, 8)}`;
        }
    };

    // Atualiza os valores do formulário
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]:
                name === "departureDate" || name === "returnDate"
                    ? formatDate(value) // Aplica a formatação de data
                    : type === "checkbox"
                        ? checked
                        : value,
        }));
    };

    // Verifica se todos os campos estão preenchidos
    const checkFormValidity = () => {
        const { name, email, departureCity, finalCity, passengers, departureDate, returnDate } =
            formData;
        if (name && email && departureCity && finalCity && passengers && departureDate && returnDate) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };

    // Atualiza a validade do formulário sempre que o valor de um campo mudar
    React.useEffect(() => {
        checkFormValidity();
    }, [formData]);

    // Gera a mensagem e redireciona para o WhatsApp
    const handleSubmit = (e) => {
        e.preventDefault();

        // Verifica se o formulário é válido antes de redirecionar
        if (!isFormValid) {
            alert("Por favor, preencha todos os campos obrigatórios.");
            return; // Impede o redirecionamento se houver campos faltando
        }

        const message = 
        ` Olá, gostaria de pedir uma cotação personalizada com as seguintes informações:
        - Nome: ${formData.name}
        - Email: ${formData.email} // Incluindo o email na mensagem
        - Cidade de partida: ${formData.departureCity}
        - Cidade de destino: ${formData.finalCity}
        - Quantidade de passageiros: ${formData.passengers}
        - Data de ida: ${formData.departureDate}
        - Data de volta: ${formData.returnDate}
        - Bagagem de 23kg: ${formData.baggage ? "Sim" : "Não"}
        - Datas flexíveis: ${formData.flexData ? "Sim" : "Não"}`;
        

        const encode = encodeURIComponent(message);
        window.open(`https://wa.me/5532984957373?text=${encode}`);
    };

    return (
        <Contain>
            <FormContainer>
                <FormHeading>
                    Preencha o formulário para pedir uma cotação personalizada:
                </FormHeading>
                <StyledForm onSubmit={handleSubmit}>
                    <FormField>
                        <Input
                            type="text"
                            name="name"
                            placeholder="Digite seu nome"
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                    </FormField>
                    <FormField>
                        <Input
                            type="email" // Tipo do input para email
                            name="email"
                            placeholder="Digite seu email"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                    </FormField>
                    <FormField>
                        <Input
                            type="text"
                            name="departureCity"
                            placeholder="Cidade de partida"
                            value={formData.departureCity}
                            onChange={handleInputChange}
                        />
                    </FormField>
                    <FormField>
                        <Input
                            type="text"
                            name="finalCity"
                            placeholder="Cidade de destino"
                            value={formData.finalCity}
                            onChange={handleInputChange}
                        />
                    </FormField>
                    <FormField>
                        <Input
                            type="number"
                            name="passengers"
                            placeholder="Quantidade de passageiros"
                            value={formData.passengers}
                            onChange={handleInputChange}
                        />
                    </FormField>
                    <FormField>
                        <Input
                            type="text"
                            name="departureDate"
                            placeholder="Data de ida (dd/mm/aaaa)"
                            value={formData.departureDate}
                            onChange={handleInputChange}
                            maxLength="10" // Limita a entrada a 10 caracteres
                        />
                    </FormField>
                    <FormField>
                        <Input
                            type="text"
                            name="returnDate"
                            placeholder="Data de Volta (dd/mm/aaaa)"
                            value={formData.returnDate}
                            onChange={handleInputChange}
                            maxLength="10"
                        />
                    </FormField>
                    <FormField>
                        <CheckboxLabel>
                            <Checkbox
                                type="checkbox"
                                name="baggage"
                                checked={formData.baggage}
                                onChange={handleInputChange}
                            />
                            QUERO BAGAGEM DE 23KG
                        </CheckboxLabel>
                    </FormField>
                    <FormField>
                        <CheckboxLabel>
                            <Checkbox
                                type="checkbox"
                                name="flexData"
                                checked={formData.flexData}
                                onChange={handleInputChange}
                            />
                            TENHO FLEXIBILIDADE NAS DATAS
                        </CheckboxLabel>
                    </FormField>
                    <SubmitButton type="submit">
                        PEDIR COTAÇÃO PERSONALIZADA
                    </SubmitButton>
                </StyledForm>
            </FormContainer>
        </Contain>
    );
}
