import styled from "styled-components";
import Header from "../Components/InitialPage/Header";
import AllPackages from "../Components/InitialPage/Allpacks/AllPacksPackges";
import Slogan from "../Components/InitialPage/Slogan";
import PackSlogan from "../Components/InitialPage/PackSlogan";
import Footer from "../Components/InitialPage/Footer";

export default function AllPacks(){
    return (<>
        <Container>
            <Header/>
            <PackSlogan/>
            <AllPackages/>
            <Footer/>
        </Container>
    </>)
}

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`